
// Angular modules
import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { IFilter } from '../../models/filter.interface';

@Component({
  selector: 'lockbin-map-filter-item-static',
  templateUrl: './map-filter-item-static.component.html',
  // styleUrls: ['./map-filter-item.component.scss'],
})
export class MapFilterItemStaticComponent implements OnInit {

  @HostBinding('class') componentCssClass = 'map-filter-item';

  showOptions = false;

  constructor(protected logger: NGXLogger) {}

  ngOnInit(): void {

    this.logger.debug('MapFilterItemComponent:ngOnInit');

  }

  doToggleOptions() {
    this.showOptions = ! this.showOptions;
  }

}
