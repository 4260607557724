// Angular modules
import { Component, EventEmitter, HostBinding, Output } from '@angular/core';
import { OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { IFilter } from '../../models/filter.interface';
import { StorageSessionHelper } from '@helpers/storage-session.helper';
import { EquipmentModel } from '@configuration/equipment-models/models';
import { EquipmentCategory } from '@configuration/equipment-categories/models';
import { EquipmentType } from '@configuration/equipment-types/models';
import { IFilterItem } from '../../models/filter-item.interface';
import { EventBusService } from '@services/event-bus.service';
import { BUS_EVENT_MAP_TYPE } from '../../enums/bus-event-map-type.enum';
import { IMapGroup } from '../../models/map-group.interface';
import { IMapZone } from '../../models/map-zone.interface';

@Component({
  selector: 'lockbin-map-filter',
  templateUrl: './map-filter.component.html',
  // styleUrls: ['./map-filter.component.scss'],
})
export class MapFilterComponent implements OnInit {

  @HostBinding('class') componentCssClass = 'map-filter';

  filterModel!: IFilter;
  filterCategory!: IFilter;
  filterType!: IFilter;
  filterZone!: IFilter;
  filterPostalCode!: IFilter;

  appliedFilters: IFilterItem[] = [];
  numItemsFiltered!: number | undefined;

  private disableBlock = false;

  constructor(protected logger: NGXLogger, protected eventBusService: EventBusService) {}

  ngOnInit(): void {
    this.logger.debug('MapFilterComponent:ngOnInit');

    this.eventBusService.on(BUS_EVENT_MAP_TYPE.FILTERS_LOADED, () => {
      this.loadFilterModel();
      this.loadFilterCategory();
      this.loadFilterTypes();
      this.loadFilterZones();
      this.loadFilterPostalCodes();
    });


    // Si recibimos un evento de aplicación de filtro
    this.eventBusService.on(BUS_EVENT_MAP_TYPE.APPLY_ISSUE_FILTER, () => {

      // Enviamos evento para que se aplique el filtro
      this.eventBusService.emit({
        name: BUS_EVENT_MAP_TYPE.APPLY_FILTERS,
        value: this.appliedFilters,
      });

    });

    // Si recibimos un evento de aplicación de filtro
    this.eventBusService.on(BUS_EVENT_MAP_TYPE.APPLY_RECOMMENDATION_FILTER, () => {

      // Enviamos evento para que se aplique el filtro
      this.eventBusService.emit({
        name: BUS_EVENT_MAP_TYPE.APPLY_FILTERS,
        value: this.appliedFilters,
      });

    });
  }

  doRemoveFilter(filterItem: IFilterItem) {

    this.removeFilterApplied(filterItem);

    this.eventBusService.emit({
      name: BUS_EVENT_MAP_TYPE.APPLY_FILTERS,
      value: this.appliedFilters,
    });

  }

  doApplyFilter(filterItem: IFilterItem) {

    this.addFilterApplied(filterItem);

    this.eventBusService.emit({
      name: BUS_EVENT_MAP_TYPE.APPLY_FILTERS,
      value: this.appliedFilters,
    });

  }

  doToggleFilters() {
    this.disableBlock = !this.disableBlock;

    if (this.disableBlock) {
      this.componentCssClass = this.componentCssClass + ' disable';
    } else {
      this.componentCssClass = this.componentCssClass.replace(' disable', '');
    }
  }

  doClearFilters() {

    this.eventBusService.emit({
      name: BUS_EVENT_MAP_TYPE.CLEAR_FILTERS,
      value: true,
    });

    this.appliedFilters = [];
    this.numItemsFiltered = undefined;

  }

  doClickOnFilterApplied(filterItem: IFilterItem) {

    this.removeFilterApplied(filterItem);

    this.eventBusService.emit({
      name: BUS_EVENT_MAP_TYPE.APPLY_FILTERS,
      value: this.appliedFilters,
    });

    // Emitimos para todos los listados de filtros individuales
    this.eventBusService.emit({
      name: BUS_EVENT_MAP_TYPE.REMOVE_FILTER,
      value: filterItem,
    });

  }

  setNumItemsFiltered(numItems: number) {

    this.numItemsFiltered = numItems;

  }

  addFilterApplied(filterItem: IFilterItem) {

    this.appliedFilters.push(filterItem);

  }

  removeFilterApplied(filterItem: IFilterItem) {

    // // Se compara con la cadena "class" y el valor
    // this.appliedFilters = this.appliedFilters.filter(item => item.class !== filterItem.class && item.value !== filterItem.value);

    const index = this.appliedFilters.indexOf(filterItem);
    if (index > -1) {
      this.appliedFilters.splice(index, 1);
    }

  }

  private loadFilterModel() {
    const equipmentModels: EquipmentModel[] = StorageSessionHelper.getItem('models');

    const filterModel: IFilter = {
      class: 'equipmentModelId',
      title: 'Modelo',
      items: [],
    };

    equipmentModels.forEach((model) => {
      if (model.name) {
        filterModel.items.push({
          class: filterModel.class,
          label: model.name,
          value: model.id,
          selected: false,
          key: '',
        });
      }
    });

    this.filterModel = filterModel;
  }

  private loadFilterCategory() {
    const equipmentCategory: EquipmentCategory[] = StorageSessionHelper.getItem('categories');

    const filterCategory: IFilter = {
      class: 'equipmentCategoryId',
      title: 'Fracción',
      items: [],
    };

    equipmentCategory.forEach((model) => {
      if (model.name) {
        filterCategory.items.push({
          class: filterCategory.class,
          label: model.name,
          value: model.id,
          selected: false,
          key: '',
        });
      }
    });

    this.filterCategory = filterCategory;
  }

  private loadFilterTypes() {
    const equipmentTypes: EquipmentType[] = StorageSessionHelper.getItem('types');

    const filterType: IFilter = {
      class: 'equipmentTypeId',
      title: 'Carga',
      items: [],
    };

    equipmentTypes.forEach((model) => {
      if (model.name) {
        filterType.items.push({
          class: filterType.class,
          label: model.name,
          value: model.id,
          selected: false,
          key: '',
        });
      }
    });

    this.filterType = filterType;
  }

  private loadFilterZones() {
    const zones: IMapZone[] = StorageSessionHelper.getItem('zones');

    const filterZone: IFilter = {
      class: 'zoneId',
      title: 'Zonas',
      items: [],
    };

    zones.forEach((zone) => {
      if (zone.name) {
        filterZone.items.push({
          class: filterZone.class,
          label: zone.name,
          value: zone.id,
          selected: false,
          key: '',
        });
      }
    });

    this.filterZone = filterZone;
  }

  private loadFilterPostalCodes() {
    const postalCodes: IMapGroup[] = StorageSessionHelper.getItem('postalCodes');

    if (postalCodes) {

      const filterPostalCodes: IFilter = {
        class: 'postalCode',
        title: 'Códigos Postales',
        items: [],
      };

      postalCodes.forEach((postalCode) => {
        if (postalCode.name) {
          filterPostalCodes.items.push({
            class: filterPostalCodes.class,
            label: postalCode.name,
            value: postalCode.id,
            selected: false,
            key: '',
          });
        }
      });

      this.filterPostalCode = filterPostalCodes;
    }
  }
}
