
<lockbin-map-sidebar-header></lockbin-map-sidebar-header>

<img class="map-sidebar-separator" src="assets/img/status-map/map-sidebar-sep.png" width="320" alt="">

<ngx-simplebar class="map-sidebar-content" [options]="options">

  <div style="height: 20px;"></div>

  <div class="px-3 mb-3">
    <lockbin-map-issues></lockbin-map-issues>
  </div>

  <div class="px-3 mb-3">
    <lockbin-map-recommendations [disableBlock]="true"></lockbin-map-recommendations>
  </div>

  <div class="px-3">
    <lockbin-map-filter></lockbin-map-filter>
  </div>

  <div class="py-3"></div>

</ngx-simplebar>
