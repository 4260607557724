// Angular modules
import { ChangeDetectorRef, Component, EventEmitter, HostBinding, Input, OnDestroy, Output } from '@angular/core';
import { OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { IMapEquipment } from '../../models/map-equipment.interface';
import { EquipmentService } from '@equipments/equipments-mgmt/services/equipment.service';
import { Subject } from 'rxjs';
import { MapEquipment } from '../../models/map-equipment.model';

@Component({
  selector: 'lockbin-equipment-info-extend',
  templateUrl: './equipment-info-extend.component.html',
  // styleUrls: ['./equipment-info-extend.component.scss'],
})
export class EquipmentInfoExtendComponent implements OnInit, OnDestroy {
  @HostBinding('class') componentCssClass = 'equipment-info-extend';

  @Input() equipment: MapEquipment | undefined;

  @Output() closeEvent = new EventEmitter<string>();

  today!: Date;

  activeTab: number = 1;

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(protected logger: NGXLogger, private equipmentService: EquipmentService, private changeDetectorRef: ChangeDetectorRef) {
    this.today = new Date();
  }

  ngOnInit(): void {
    this.logger.debug('EquipmentInfoExtendComponent:ngOnInit');
  }

  openInfoEquipment(equipmentMap: IMapEquipment) {
    this.logger.debug('openInfoEquipment', 'Cargamos toda la información del Equipo:', equipmentMap.id);
  }

  closeInfo() {
    this.equipment = undefined;

    this.closeEvent.emit('Close');
  }

  doClickClose() {
    this.closeInfo();
  }

  resetTabActive() {
    this.activeTab = 1;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
