// Angular modules
import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { IMapRecommendation } from '../../models/map-recommendation.interface';
import { EventBusService } from '@services/event-bus.service';
import { BUS_EVENT_MAP_TYPE } from '../../enums/bus-event-map-type.enum';

@Component({
  selector: 'lockbin-map-recommendations',
  templateUrl: './map-recommendations.component.html',
  // styleUrls: ['./map-recommendations.component.scss'],
})
export class MapRecommendationsComponent implements OnInit {
  @HostBinding('class') componentCssClass = 'map-issues map-recommendations';

  @Input() disableBlock: boolean = false;
  @Input() recommendations!: IMapRecommendation[];

  @Output() applyRecommendation: EventEmitter<IMapRecommendation> = new EventEmitter();
  @Output() removeRecommendation: EventEmitter<IMapRecommendation> = new EventEmitter();

  constructor(protected logger: NGXLogger, protected eventBusService: EventBusService) {}

  ngOnInit(): void {
    this.logger.debug('MapRecommendationsComponent:ngOnInit');
  }

  recommendationsTotalCounter() {
    let counter = 0;

    if (this.recommendations) {
      this.recommendations.forEach((item) => {
        counter += item.quantity;
      });
    }

    return counter;
  }

  hasAnyRecommendationActive() {
    return this.recommendations && this.recommendations.some((item) => item.active);
  }

  doToggleRecommendation(recommendation: IMapRecommendation) {
    if (recommendation.active) {
      recommendation.active = false;
      this.removeRecommendation.emit(recommendation);
    } else {
      this.recommendations.forEach((item) => {
        item.active = false;
      });

      recommendation.active = true;

      // Enviar selección de incidencia
      this.applyRecommendation.emit(recommendation);
    }
  }

  doToggleRecommendations() {
    this.disableBlock = !this.disableBlock;

    if (this.disableBlock) {
      this.componentCssClass = this.componentCssClass + ' disable';
      this.disableAll();

      this.eventBusService.emit({
        name: BUS_EVENT_MAP_TYPE.DISABLE_RECOMMENDATIONS,
        value: true,
      });
    } else {
      this.componentCssClass = this.componentCssClass.replace(' disable', '');

      this.eventBusService.emit({
        name: BUS_EVENT_MAP_TYPE.ENABLE_RECOMMENDATIONS,
        value: true,
      });
    }
  }

  private disableAll() {
    this.recommendations.forEach((issue) => {
      issue.active = false;
    });
  }
}
