import { HttpClient } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, ComponentRef, ElementRef, EventEmitter, Inject, OnDestroy, OnInit, Output, Renderer2, ViewChild, ViewContainerRef } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subject, catchError, filter, forkJoin, interval, map, of, takeUntil, takeWhile, tap } from 'rxjs';

import { EquipmentInfoComponent } from '../../components/equipment-info/equipment-info.component';
import { MapEquipmentService } from '../../services/map-equipment.service';
import { EquipmentModelService } from '@configuration/equipment-models/services/equipment-model.service';
import { EquipmentTypeService } from '@configuration/equipment-types/services/equipment-type.service';
import { EquipmentCategoryService } from '@configuration/equipment-categories/services/equipment-category.service';
import { IMapEquipment } from '../../models/map-equipment.interface';
import { StorageSessionHelper } from '@helpers/storage-session.helper';
import { EventBusService } from '@services/event-bus.service';
import { BUS_EVENT_MAP_TYPE } from '../../enums/bus-event-map-type.enum';
import { IMapIssue } from '../../models/map-issue.interface';
import { Cluster, ClusterStats, Marker, MarkerClusterer, Renderer, SuperClusterAlgorithm } from '@googlemaps/markerclusterer';
import { MapFilterComponent } from '../../components/map-filter/map-filter.component';
import { IFilterItem } from '../../models/filter-item.interface';
import { DOCUMENT } from '@angular/common';
import { IMapRecommendation } from '../../models/map-recommendation.interface';
import { StatusMapHelper } from '../../helpers/status-map.helper';
import { EquipmentIssueHelper } from '../../helpers/equipment-issue.helper';
import { EquipmentRecommendationHelper } from '../../helpers/equipment-recommendation.helper';
import { EquipmentInfoExtendComponent } from '../../components/equipment-info-extend/equipment-info-extend.component';
import { MapEquipment } from '../../models/map-equipment.model';
import { IMapHeaderData } from '../../models/map-header-data.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageHelper } from '@helpers/storage.helper';
import { StorageKey } from '@enums/storage-key.enum';
import { environment } from '@env/environment';
import { EnvName } from '@enums/environment.enum';
import { UserAuthApiDto } from '@security/models';

@Component({
  selector: 'lockbin-map-view',
  templateUrl: './map-view.component.html',
  styles: [],
})
export class MapViewComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('mapEquipment') mapEquipmentElement!: ElementRef;
  @ViewChild('mapTypeControl', { static: false }) mapTypeControl!: ElementRef;
  @ViewChild(MapFilterComponent, { static: false }) mapFilter!: MapFilterComponent;
  @ViewChild(EquipmentInfoComponent, { static: false }) equipmentInfo!: EquipmentInfoComponent;
  @ViewChild(EquipmentInfoExtendComponent, { static: false }) equipmentInfoExtend!: EquipmentInfoExtendComponent;

  sidebarCompact = false;
  showEquipmentInfo = false;
  showEquipmentExtendInfo = false;

  equipmentsList!: IMapEquipment[];

  // Variables del Mapa
  googleMapApiLoaded = false;
  googleMapEquipment!: google.maps.Map;
  mapTypeId!: google.maps.MapTypeId;
  equipmentMarkers: google.maps.marker.AdvancedMarkerElement[] = [];
  markerCluster!: MarkerClusterer;

  // Issues
  issues!: IMapIssue[];
  disableIssues = false;
  issueFilter!: IMapIssue | null;

  // Recommendations
  recommendations!: IMapRecommendation[];
  disableRecommendations = false;
  recommendationFilter!: IMapRecommendation | null;

  // Para los datos de la cabecera
  headerData!: IMapHeaderData;

  roleUser!: string;

  public optionsSimpleBarSidebar = { autoHide: true, scrollbarMinSize: 100 };

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    protected logger: NGXLogger,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private mapEquipmentService: MapEquipmentService,
    private equipmentModelService: EquipmentModelService,
    private equipmentTypeService: EquipmentTypeService,
    private equipmentCategoryService: EquipmentCategoryService,
    protected eventBusService: EventBusService,
    private changeDetectorRef: ChangeDetectorRef,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit(): void {
    this.logger.debug('ngOnInit');

    this.addEventBusListeners();

    this.loadFilters();

    this.initMap();

  }

  ngAfterViewInit(): void {
    // Get param idEquipment and load Equipment Single Card
    this.activatedRoute.params
      .subscribe(params => {
        const idEquipment = params['idEquipment'];
        if (idEquipment) {
          this.eventBusService.on(BUS_EVENT_MAP_TYPE.MAP_LOADED, () => {
            this.logger.debug('ngAfterViewInit', 'EVENT', BUS_EVENT_MAP_TYPE.MAP_LOADED);
            const markerEquipment = this.equipmentMarkers.find((marker: google.maps.marker.AdvancedMarkerElement) => {
              return marker.dataset['equipmentId'] === idEquipment.toString();
            });
            if (markerEquipment && markerEquipment.position) {
              const mapEquipment = this.equipmentsList.find((equipment) => equipment.id.toString() === idEquipment);
              if (mapEquipment) {
                  this.googleMapEquipment.setCenter(markerEquipment.position);
                  this.googleMapEquipment.setZoom(21);
                  this.doClickOnMarker(markerEquipment, mapEquipment);
              }
            }
          });
        }
      });
  }

  addEventBusListeners() {
    this.eventBusService.on(BUS_EVENT_MAP_TYPE.APPLY_FILTERS, (filtersApplied: IFilterItem[]) => {
     this.logger.debug('addEventBusListeners', 'EVENT', BUS_EVENT_MAP_TYPE.APPLY_FILTERS, filtersApplied);

      this.doApplyAllFilters(filtersApplied);

    });

    this.eventBusService.on(BUS_EVENT_MAP_TYPE.ENABLE_ISSUES, (issue: IMapIssue) => {
     this.logger.debug('addEventBusListeners', 'EVENT', BUS_EVENT_MAP_TYPE.ENABLE_ISSUES, issue);

      this.doEnableIssues();
    });

    this.eventBusService.on(BUS_EVENT_MAP_TYPE.DISABLE_ISSUES, () => {
     this.logger.debug('addEventBusListeners', 'EVENT', BUS_EVENT_MAP_TYPE.DISABLE_ISSUES);

      this.doDisableIssues();
    });

    this.eventBusService.on(BUS_EVENT_MAP_TYPE.ENABLE_RECOMMENDATIONS, (issue: IMapRecommendation) => {
     this.logger.debug('addEventBusListeners', 'EVENT', BUS_EVENT_MAP_TYPE.ENABLE_RECOMMENDATIONS, issue);

      this.doEnableRecommendations();
    });

    this.eventBusService.on(BUS_EVENT_MAP_TYPE.DISABLE_RECOMMENDATIONS, () => {
     this.logger.debug('addEventBusListeners', 'EVENT', BUS_EVENT_MAP_TYPE.DISABLE_RECOMMENDATIONS);

      this.doDisableRecommendations();
    });

    this.eventBusService.on(BUS_EVENT_MAP_TYPE.CLEAR_FILTERS, () => {
     this.logger.debug('addEventBusListeners', 'EVENT', BUS_EVENT_MAP_TYPE.CLEAR_FILTERS);

      this.mapFilterClear();
    });

    this.eventBusService.on(BUS_EVENT_MAP_TYPE.REMOVE_FILTER, (filterItem: IFilterItem) => {
     this.logger.debug('addEventBusListeners', 'EVENT', BUS_EVENT_MAP_TYPE.REMOVE_FILTER, filterItem);

      // this.mapFilterRemove(filterItem);
    });
  }

  async initMap(): Promise<void> {
    // const { Map } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;
    (await google.maps.importLibrary('maps')) as google.maps.MapsLibrary;
    (await google.maps.importLibrary('marker')) as google.maps.MarkerLibrary;

    const googleMapsOptions: google.maps.MapOptions = {
      // center: { lat: 42.338337, lng: -7.8661373 },
      center: { lat: 42.29360560117137, lng: -7.810994891601288 },
      zoom: 12,
      mapId: '47fdee771bf7072f',
      backgroundColor: '#f7f7f7',
      fullscreenControl: false,
      scrollwheel: true,
      // styles: MapStyles,
      mapTypeControl: false,
      mapTypeControlOptions: {
        style: 2,
        mapTypeIds: ['roadmap', 'satellite'],
        position: 9.0, // google.maps.ControlPosition.LEFT_TOP
      },
      zoomControl: true,
      zoomControlOptions: {
        position: 9.0, // google.maps.ControlPosition.RIGHT_TOP
      },
      streetViewControl: true,
      streetViewControlOptions: {
        position: 9.0,
      },
      disableDefaultUI: true,
    };

    // Cargamos el Mapa
    this.googleMapEquipment = new google.maps.Map(this.mapEquipmentElement.nativeElement, googleMapsOptions);

    this.googleMapEquipment.addListener('click', (event: google.maps.MapMouseEvent | google.maps.IconMouseEvent) => {
      this.logger.debug('Click on map', event.latLng?.toString());
      event.stop();
    });

    this.googleMapEquipment.addListener('zoom_changed', () => {
      this.onChangeZoom();
    });

    // La variable que establece el tipo de mapa
    this.mapTypeId = google.maps.MapTypeId.ROADMAP;

    // Se indica que todo ha cargado
    this.googleMapApiLoaded = true;

    // Cargamos los equipos y los mostramos en el mapa.
    this.loadEquipment().subscribe((data: IMapEquipment[]) => {
      this.logger.debug('loadEquipment', data);
      this.equipmentsList = data ? data : [];
      this.buildMarkers(this.equipmentsList);
    });
  }

  loadEquipment() {
    return this.mapEquipmentService.findAll().pipe(
      takeUntil(this.unsubscribe$),
      map((equipmentMapList) => {

        if (equipmentMapList) {

          // Para gestionar los datos dummy de incidencias y recomendaciones
          // if (environment.envName === EnvName.PRE || environment.envName === EnvName.LOCAL) {
            const username: UserAuthApiDto = StorageHelper.getItem(StorageKey.USER);
            if (username && username.username === 'comercial') {
              equipmentMapList = StatusMapHelper.setDummyData(equipmentMapList);
            }
          // }
          // equipmentMapList = StatusMapHelper.setDummyData(equipmentMapList);

          // Añadimos al listado de incidencias las que se extraen del equipo
          equipmentMapList = EquipmentIssueHelper.setIssuesData(equipmentMapList);

          this.setHeaderTotalStats(equipmentMapList.length, this.setIssuesStats(equipmentMapList));

          // Añadimos al listado de recomendaciones las que se extraen del equipo
          equipmentMapList = EquipmentRecommendationHelper.setRecommendationsData(equipmentMapList);

          this.setRecommendationsStats(equipmentMapList);

        }

        return equipmentMapList;
      })
    );
  }

  loadFilters() {
    const equipmentModels$ = this.equipmentModelService.find({});
    const equipmentCategories$ = this.equipmentCategoryService.find({});
    const equipmentTypes$ = this.equipmentTypeService.find({});
    const equipmentZones$ = this.mapEquipmentService.findZones();

    // const groupTypes$ = this.mapEquipmentService.findGroupTypes();
    // const groups$ = this.mapEquipmentService.findGroups();

    const postalCodes$ = this.mapEquipmentService.findPostalCodes();

    forkJoin([equipmentModels$, equipmentCategories$, equipmentTypes$, equipmentZones$, postalCodes$])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: data => {
          StorageSessionHelper.setItem(StorageKey.SESSION_MODELS, data[0].items);
          StorageSessionHelper.setItem(StorageKey.SESSION_CATEGORIES, data[1].items);
          StorageSessionHelper.setItem(StorageKey.SESSION_TYPES, data[2].items);
          // TODO: la API devuelve zonas como un texto vacío y la llamada a la API lo interpreta como null, debería ser un array vacío
          if (data[3]) {
            StorageSessionHelper.setItem(StorageKey.SESSION_ZONES, data[3]);
          } else {
            StorageSessionHelper.setItem(StorageKey.SESSION_ZONES, []);
          }
          StorageSessionHelper.setItem(StorageKey.SESSION_POSTALCODES, data[4]);

          // console.log(data[4]);

          this.eventBusService.emit({ name: BUS_EVENT_MAP_TYPE.FILTERS_LOADED, value: true });
        },
        error: error => {
          console.error(error);
          // Probablemente No tenemos permisos redirigimos a Login
          this.router.navigate(['/autenticacion/acceso']);

        },
      });
  }

  // --
  // EVENTOS

  onChangeZoom() {
    const mapZoom = this.googleMapEquipment.getZoom();

    this.logger.debug('onChangeZoom', 'New zoom:', mapZoom);

    // if (mapZoom && mapZoom >= 19) {
    //   this.map.googleMap?.setMapTypeId('satellite');
    // } else {
    //   if (this.map.getMapTypeId() === 'roadmap') {
    //   }
    // }
  }

  toggleMapType(): void {
    this.mapTypeId = this.mapTypeId === google.maps.MapTypeId.ROADMAP ? google.maps.MapTypeId.SATELLITE : google.maps.MapTypeId.ROADMAP;

    this.googleMapEquipment.setMapTypeId(this.mapTypeId);
    // this.map.googleMap?.setMapTypeId(this.mapTypeId);
  }

  doCloseEquipmentInfo() {
    this.showEquipmentInfo = false;
    // this.sidebarCompact = false;

    this.resetAllMarkers();
  }

  doClickEquipment(equipment: IMapEquipment) {

    this.showEquipmentInfo = true;

    // this.equipmentInfo.equipment = equipment;

    this.equipmentInfo.openInfoEquipment(equipment);

    // // Tenemos que forzar la detección de cambios porque no los detecta.
    this.changeDetectorRef.detectChanges();
  }

  doResetEquipment() {
    this.equipmentInfo.closeInfo();

    // // Tenemos que forzar la detección de cambios porque no los detecta.
    this.changeDetectorRef.detectChanges();
  }


  doApplyIssue(issue: IMapIssue) {

    this.issueFilter = issue;

    // Enviamos para que emita evento el bloque de filtros
    this.eventBusService.emit({ name: BUS_EVENT_MAP_TYPE.APPLY_ISSUE_FILTER, value: true });

  }

  doRemoveIssue() {

    this.issueFilter = null;

    // Enviamos para que emita evento el bloque de filtros
    this.eventBusService.emit({ name: BUS_EVENT_MAP_TYPE.APPLY_ISSUE_FILTER, value: true });

  }

  doApplyRecommendation(recommendation: IMapRecommendation) {

    this.recommendationFilter = recommendation;

    this.logger.debug('doApplyRecommendation', this.recommendationFilter);

    // Enviamos para que emita evento el bloque de filtros
    this.eventBusService.emit({ name: BUS_EVENT_MAP_TYPE.APPLY_RECOMMENDATION_FILTER, value: true });

  }

  doRemoveRecommendation() {

    this.recommendationFilter = null;

    // Enviamos para que emita evento el bloque de filtros
    this.eventBusService.emit({ name: BUS_EVENT_MAP_TYPE.APPLY_RECOMMENDATION_FILTER, value: true });

  }

  doMoreInfoEquipment(equipment: MapEquipment) {

    this.setAndShowMoreInfoEquipment(equipment);

  }

  doHideMoreInfoEquipment() {

    this.hideMoreInfoEquipment();

  }

  doOpenExtendedEquipmentInfo() {

    this.equipmentInfo.doClickMoreInfo();

  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private buildMarkers(mapEquipments: IMapEquipment[]) {
    if (mapEquipments.length > 0) {
      // const markers: Marker[] = [];

      // Vamos a procesar los puntos de los equipos para separar ligeramente los que están en la misma posición
      this.shiftLatLng(mapEquipments);

      mapEquipments.forEach((mapEquipment) => {
        let issuesHtml = StatusMapHelper.getMarkerIconIssues(mapEquipment);
        if (issuesHtml.length > 0) {
          issuesHtml = '<div class="lb-marker__issue">' + issuesHtml + '</div>';
        }

        let recommendationsHtml = StatusMapHelper.getMarkerIconRecommendations(mapEquipment);
        if (recommendationsHtml.length > 0) {
          recommendationsHtml = '<div class="lb-marker__recommendation">' + recommendationsHtml + '</div>';
        }

        const content = this.document.createElement('div');
        content.classList.add('lb-marker');
        content.innerHTML = `
          ${issuesHtml}
          ${recommendationsHtml}
          <div class="lb-marker__image">
              <img class="lb-marker__image__source" width="64" height="64" src="${StatusMapHelper.getImageMarkerUrl(mapEquipment)}" alt="">
          </div>
          `;

        const marker = new google.maps.marker.AdvancedMarkerElement({
          // map: this.googleMapEquipment,
          position: {
            lat: mapEquipment.latitudeAlt ? mapEquipment.latitudeAlt : mapEquipment.latitude,
            lng: mapEquipment.longitudeAlt ? mapEquipment.longitudeAlt : mapEquipment.longitude,
          },
          title: mapEquipment.id.toString(),
          content: content,
        });

        if (issuesHtml.length > 0) {
          marker.content?.parentElement?.classList.add('has-issue');
        }

        if (recommendationsHtml.length > 0) {
          marker.content?.parentElement?.classList.add('has-recommendation');
        }

        // Establecemos valores en el Marker

        marker.dataset['equipmentId'] = mapEquipment.id.toString();

        marker.dataset['equipmentModelId'] = mapEquipment.equipmentModelId.toString();
        marker.dataset['equipmentCategoryId'] = mapEquipment.equipmentCategoryId.toString();
        marker.dataset['equipmentTypeId'] = mapEquipment.equipmentTypeId.toString();

        marker.dataset['zoneId'] = mapEquipment.zoneId ? mapEquipment.zoneId.toString() : '0';

        marker.dataset['postalCode'] = mapEquipment.postalCode ? mapEquipment.postalCode.toString() : '';

        marker.dataset['alertCommunications'] = mapEquipment.alertCommunications ? '1' : '0';
        marker.dataset['alertDamageServo'] = mapEquipment.alertDamageServo ? '1' : '0';
        marker.dataset['alertDumped'] = mapEquipment.alertDumped ? '1' : '0';
        marker.dataset['alertFire'] = mapEquipment.alertFire ? '1' : '0';
        marker.dataset['alertLocked'] = mapEquipment.alertLocked ? '1' : '0';
        marker.dataset['alertNoBattery'] = mapEquipment.alertNoBattery ? '1' : '0';
        marker.dataset['alertOpenCover'] = mapEquipment.alertOpenCover ? '1' : '0';

        marker.dataset['issues'] = JSON.stringify(StatusMapHelper.getIssuesForDataset(mapEquipment));

        marker.dataset['recomendationChangeBattery'] = mapEquipment.recomendationChangeBattery ? '1' : '0';
        marker.dataset['recomendationChangeLocation'] = mapEquipment.recomendationChangeLocation ? '1' : '0';

        marker.dataset['recomendations'] = JSON.stringify(StatusMapHelper.getRecommendationsForDataset(mapEquipment));

        // Indicamos la acción en el evento de Click del marker
        marker.addListener('click', () => {
          this.doClickOnMarker(marker, mapEquipment);
        });

        this.equipmentMarkers.push(marker);
      });

      const interpolatedRenderer: Renderer = {
        render: this.interpolatedRenderer.bind(this),
      };

      this.markerCluster = new MarkerClusterer({
        map: this.googleMapEquipment,
        markers: this.equipmentMarkers,
        // algorithmOptions: { maxZoom: 18 },
        algorithm: new SuperClusterAlgorithm({ radius: 150, maxZoom: 18 }),
        renderer: interpolatedRenderer,
      });

      // console.log(this.markerCluster);

      // Cuando hay pocos markers los clusters no funcionan, así que los asignamos directamente al mapa
      if (this.equipmentMarkers.length < 5) {
        this.equipmentMarkers.forEach((marker: google.maps.marker.AdvancedMarkerElement) => {
          marker.map = this.googleMapEquipment;
        });
      }

      // Posicionar en los límites del mapa
      this.fitBoundsMapAll();

      // Generamos un evento para indicar que se ha cargado todos los elementos del mapa
      this.eventBusService.emit({ name: BUS_EVENT_MAP_TYPE.MAP_LOADED, value: true });
    }
  }

  private findLatLngDuplicatesInGroups(mapEquipments: IMapEquipment[]): any[] {
    const locationMap = new Map();

    // Contar cuántas veces aparece cada combinación de latitud y longitud
    mapEquipments.forEach(mapEquipment => {
      const key = `${mapEquipment.latitude},${mapEquipment.longitude}`;
      if (!locationMap.has(key)) {
        locationMap.set(key, []);
      }
      locationMap.get(key).push(mapEquipment);
    });

    // Filtrar y devolver solo los grupos con más de un elemento (los duplicados)
    const duplicates = Array.from(locationMap.values()).filter(group => group.length > 1);

    // Filtrar las ubicaciones que aparecen más de una vez
    return duplicates;
  }

  private distributeLatLngRadially(duplicateGroups: any[], baseRadius = 0.00005) {

    duplicateGroups.forEach(group => {
      const numMarkers = group.length; // Número de marcadores en el grupo (duplicados)

      // if (numMarkers > 1) {
      //   this.logger.debug('distributeLatLngRadially', group);
      // }

      if (numMarkers > 1) {
        const center = group[0]; // Primer marcador queda en el centro

        const angleIncrement = (2 * Math.PI) / (numMarkers - 1); // Ángulo entre cada marcador, excepto el primero
        const radius = baseRadius; // Mantener un radio fijo para el primer anillo

        // Distribuir el resto alrededor del primero
        group.slice(1).forEach((mapEquipment: IMapEquipment, index: number) => {
          const angle = index * angleIncrement; // Calcular el ángulo para cada marcador

          // Calcular la nueva posición radial alrededor del punto central
          mapEquipment.latitudeAlt = center.latitude + radius * Math.cos(angle);
          mapEquipment.longitudeAlt = center.longitude + radius * Math.sin(angle);

          // console.log(`Marker ${mapEquipment.id} distribuido alrededor de ${center.id} en: Latitud: ${mapEquipment.latitudeAlt}, Longitud: ${mapEquipment.longitudeAlt}`);
        });

      }
    });
  }

  private shiftLatLng(mapEquipments: IMapEquipment[]) {

    const duplicateGroups = this.findLatLngDuplicatesInGroups(mapEquipments);

    this.logger.debug('shiftLatLng', `Hay ${duplicateGroups.length} grupos de equipos en la misma posición.`);

    this.distributeLatLngRadially(duplicateGroups);

  }

  private interpolatedRenderer(cluster: Cluster, stats: ClusterStats, map: google.maps.Map): Marker {
    const { count, position } = cluster;

    const alertHtml = StatusMapHelper.getIssuesMarkerHtml(cluster);
    const recommendationHtml = StatusMapHelper.getRecommendationMarkerHtml(cluster);

    // Calculamos el porcentaje de escalado en función del número de elementos del cluster
    let sizePercentage = 1;
    switch (count.toString().length) {
      case 1:
        sizePercentage = 0.625;
        break;

      case 2:
        sizePercentage = 1;
        break;

      case 3:
        sizePercentage = 1.25;
        break;

      case 4:
        sizePercentage = 1.75;
        break;

      default:
        break;
    }

    const clusterContent = this.document.createElement('div');
    clusterContent.classList.add('lb-cluster__content');
    clusterContent.style.transform = 'scale(' + sizePercentage + ')';
    clusterContent.innerHTML = `${count}`;

    const content = this.document.createElement('div');
    content.classList.add('lb-cluster');

    content.appendChild(clusterContent);

    // const sizePercentage = count / stats.clusters.markers.max + 0.4;

    // const clusterContent = this.document.createElement('div');
    // clusterContent.classList.add('lb-cluster__content');
    // clusterContent.style.transform = 'scale(' + sizePercentage + ')';
    // clusterContent.innerHTML = `${count}`;

    // const content = this.document.createElement('div');
    // content.classList.add('lb-cluster');

    // content.appendChild(clusterContent);

    if (alertHtml.length > 0) {
      const clusterAlert = this.document.createElement('div');
      clusterAlert.classList.add('lb-marker__issue');

      const space = 20 * sizePercentage;
      clusterAlert.style.top = `-${space}%`;
      clusterAlert.style.right = `-${space}%`;

      clusterAlert.innerHTML = alertHtml;

      content.appendChild(clusterAlert);
    }

    if (recommendationHtml.length > 0) {
      const clusterRecommendation = this.document.createElement('div');
      clusterRecommendation.classList.add('lb-marker__recommendation');

      const space = 20 * sizePercentage;
      clusterRecommendation.style.top = `-${space}%`;
      clusterRecommendation.style.left = `-${space}%`;

      clusterRecommendation.innerHTML = recommendationHtml;

      content.appendChild(clusterRecommendation);
    }

    return new google.maps.marker.AdvancedMarkerElement({
      map: map,
      position,
      title: count + ' contenedores',
      content: content,
      zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
    });
  }

  private resetAllMarkers() {
    this.equipmentMarkers.forEach((marker: google.maps.marker.AdvancedMarkerElement) => {
      marker.content?.parentElement?.classList.remove('highlight');
      marker.zIndex = null;
    });
  }

  private fitBoundsMapAll() {
    // Posicionar en los límites del mapa
    const bounds = new google.maps.LatLngBounds();
    this.equipmentMarkers.forEach((marker: google.maps.marker.AdvancedMarkerElement) => {
      bounds.extend(marker.position as google.maps.LatLngLiteral);
    });
    this.googleMapEquipment.fitBounds(bounds);
  }

  private fitBoundsMap(positionsForBounds: google.maps.LatLngLiteral[]) {
    // Posicionar en los límites del mapa
    if (positionsForBounds.length > 0) {
      const bounds = new google.maps.LatLngBounds();
      positionsForBounds.forEach((position: google.maps.LatLngLiteral) => {
        bounds.extend(position);
      });
      this.googleMapEquipment.fitBounds(bounds);
    }
  }

  private doClickOnMarker(marker: google.maps.marker.AdvancedMarkerElement, mapEquipment: IMapEquipment) {

    if (marker.content?.parentElement?.classList.contains('highlight')) {

      marker.content?.parentElement?.classList.remove('highlight');
      marker.zIndex = null;

      this.doResetEquipment();

    } else {

      this.resetAllMarkers();

      marker.content?.parentElement?.classList.add('highlight');
      marker.zIndex = 10000000;

      this.doClickEquipment(mapEquipment);
    }

    this.equipmentInfoExtend.resetTabActive();
  }

  // --
  // Filter maps markers

  private mapFilterBuildGrouped(filtersApplied: IFilterItem[]) {

    // Agrupamos los filtros
    const filtersGrouped = new Map<string, IFilterItem[]>();

    filtersApplied.forEach((filterItem: IFilterItem) => {

      if (filtersGrouped.has(filterItem.class)) {
        const filters: IFilterItem[] | undefined = filtersGrouped.get(filterItem.class);
        if (filters) {
          filters?.push(filterItem);
          filtersGrouped.set(filterItem.class, filters);
        }
      } else {
        filtersGrouped.set(filterItem.class, [filterItem]);
      }

    });

    this.logger.debug('mapFilterApply', 'Se agrupan los filtros:');
    this.logger.debug('mapFilterApply', filtersGrouped);

    return filtersGrouped;
  }

  private mapFilterApply(filtersApplied: IFilterItem[]) {

    // Agrupamos los filtros
    const filtersGrouped = this.mapFilterBuildGrouped(filtersApplied);

    const listFilters = Array.from(filtersGrouped, ([key, value]) => ({ key, value }));

    let counterFiltered = 0;
    const idsToShow: string[] = [];

    this.equipmentMarkers.forEach((marker: google.maps.marker.AdvancedMarkerElement) => {

      const result = listFilters.every(filter => {

        return filter.value.some(condition => {

          return this.verificarCondicion(marker, condition);

        });

      });

      if (result && marker.dataset['equipmentId']) {
        idsToShow.push(marker.dataset['equipmentId'].toString());
      }

    });

    // console.log(idsToShow);

    // Ahora sabemos los equipos que son calculamos las incidencias

    // const currentEquipments: IMapEquipment[] = [];
    // this.equipmentsList.forEach((equipment) => {
    //   if (idsToShow.includes(equipment.id.toString())) {
    //     currentEquipments.push(equipment);
    //   }
    // });
    // this.setIssuesStats(currentEquipments);

    const positionsForBounds: google.maps.LatLngLiteral[] = [];

    // Esto hace que se oculten y se muestren

    this.markerCluster.clearMarkers();

    this.equipmentMarkers.forEach((advancedMarker) => {

      // console.log(advancedMarker);

      if (advancedMarker.dataset['equipmentId'] && idsToShow.includes(advancedMarker.dataset['equipmentId'])) {
        advancedMarker.map = this.googleMapEquipment;
        this.markerCluster.addMarker(advancedMarker);
        positionsForBounds.push(advancedMarker.position as google.maps.LatLngLiteral);
      } else {
        advancedMarker.map = null;
        this.markerCluster.removeMarker(advancedMarker);
      }

    });

    counterFiltered = idsToShow.length;

    // console.log(counterFiltered);

    this.mapFilter.setNumItemsFiltered(counterFiltered);

    this.fitBoundsMap(positionsForBounds);
  }

  private doApplyAllFilters(filtersApplied: IFilterItem[]) {

    // Tenemos que clonar para que no se aplique siempre la incidencia
    const filtersCloned = [ ...filtersApplied ];

    // Añadimos el filtro por incidencia si es necesario
    if (this.issueFilter) {
      filtersCloned.push(
        {
          class: this.issueFilter.filterClass,
          label: this.issueFilter.filterClass,
          value: '1',
          selected: false,
          key: ''
        }
      );
    }

    // Añadimos el filtro por recomendación si es necesario
    if (this.recommendationFilter) {
      filtersCloned.push(
        {
          class: this.recommendationFilter.filterClass,
          label: this.recommendationFilter.filterClass,
          value: '1',
          selected: false,
          key: ''
        }
      );
    }


    if (filtersCloned.length > 0) {
      this.mapFilterApply(filtersCloned);
    } else {
      this.mapFilterClear();
    }
  }

  private verificarCondicion(marker: google.maps.marker.AdvancedMarkerElement, filter: IFilterItem): boolean {
    const markerValue = marker.dataset[filter.class];

    const resultado = markerValue === filter.value.toString();

    // console.log(`Condición: ${filter.class} ${filter.value}, Resultado: ${resultado}`);

    return resultado;
  }

  private mapFilterClear() {
    this.equipmentMarkers.forEach((advancedMarker) => {
      advancedMarker.map = this.googleMapEquipment;
    });

    this.markerCluster.clearMarkers();
    this.markerCluster.addMarkers(this.equipmentMarkers);

    this.fitBoundsMapAll();
  }

  private setIssuesStats(mapEquipments: IMapEquipment[]) {
    const issuesStats = EquipmentIssueHelper.setIssuesStats(mapEquipments);

    // Ahora establecemos las incidencias en la variable del component
    this.issues = Array.from(issuesStats.values());

    return issuesStats;
  }

  private setHeaderTotalStats(totalEquipments: number, issuesStats: Map<string, IMapIssue>) {

    // Establecemos aquí los valores de la cabecera
    let totalAlerts = 0;
    issuesStats.forEach((value) => {
      totalAlerts += value.quantity;
    });

    const percentageCorrect = 100 - Math.round((totalAlerts * 100) / totalEquipments);

    this.headerData = {
      percentageCorrect: percentageCorrect,
      totalEquipments: totalEquipments,
    };

  }

  private setRecommendationsStats(mapEquipments: IMapEquipment[]) {
    const recommendationsStats = EquipmentRecommendationHelper.setRecommendationsStats(mapEquipments);

    // Ahora establecemos las incidencias en la variable del component
    this.recommendations = Array.from(recommendationsStats.values());
  }

  private doDisableIssues() {

    this.disableIssues = true;

  }

  private doEnableIssues() {

    this.disableIssues = false;

  }

  private doDisableRecommendations() {

    this.disableRecommendations = true;

  }

  private doEnableRecommendations() {

    this.disableRecommendations = false;

  }


  private setAndShowMoreInfoEquipment(equipment: MapEquipment) {

    this.equipmentInfoExtend.equipment = equipment;

    this.showEquipmentExtendInfo = true;

    // Tenemos que forzar la detección de cambios porque no los detecta.
    this.changeDetectorRef.detectChanges();

  }


  private hideMoreInfoEquipment() {

    this.equipmentInfoExtend.equipment = undefined;

    this.showEquipmentExtendInfo = false;

    // Tenemos que forzar la detección de cambios porque no los detecta.
    this.changeDetectorRef.detectChanges();

  }

}
