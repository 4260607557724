// Angular modules
import { ChangeDetectorRef, Component, ComponentRef, EventEmitter, HostBinding, Input, OnDestroy, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { IMapEquipment } from '../../models/map-equipment.interface';
import { EquipmentService } from '@equipments/equipments-mgmt/services/equipment.service';
import { EMPTY, map, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { Equipment } from '@equipments/equipments-mgmt/models';
import { IEquipmentIssue } from '../../models/equipment-issue.interface';
import { MapEquipment } from '../../models/map-equipment.model';
import { StatusMapHelper } from '../../helpers/status-map.helper';
import { MapEquipmentService } from '../../services/map-equipment.service';

@Component({
  selector: 'lockbin-equipment-info',
  templateUrl: './equipment-info.component.html',
  // styleUrls: ['./equipment-info.component.scss'],
})
export class EquipmentInfoComponent implements OnInit, OnDestroy {
  @HostBinding('class') componentCssClass = 'equipment-info';

  @Input() showEquipmentExtendInfo: boolean = false;

  @Output() closeEvent = new EventEmitter<void>();
  @Output() showMoreInfoEvent = new EventEmitter<MapEquipment>();
  @Output() hideMoreInfoEvent = new EventEmitter<void>();

  equipment: MapEquipment | undefined;
  backgroundUrl = '';

  issues!: IEquipmentIssue;

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(protected logger: NGXLogger, private mapEquipmentService: MapEquipmentService, private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.logger.debug('EquipmentInfoComponent:ngOnInit');
  }

  openInfoEquipment(equipmentMap: IMapEquipment) {
    this.logger.debug('openInfoEquipment', 'Cargamos toda la información del Equipo:', equipmentMap.id);

    this.mapEquipmentService
      .findById(equipmentMap.id)
      .pipe(
        takeUntil(this.unsubscribe$),
        switchMap((equipment: Equipment) => {
          if (equipment) {
            const equipmentData = new MapEquipment(equipment);
            return this.mapEquipmentService
              .findEquipmentStatistics(equipmentMap.id)
              .pipe(
                map((statistics) => {
                  equipmentData.statistics = statistics;
                  return equipmentData;
                })
              );
          } else {
            // Si no se encuentra el equipo, lanzamos un observable vacío.
            return EMPTY;
          }
        }),
        tap((equipmentData) => {
          if (equipmentData) {
            this.equipment = equipmentData;
            this.equipment.issues = equipmentMap.issues;
            this.equipment.recommendations = equipmentMap.recommendations;
            this.backgroundUrl = `url("${StatusMapHelper.getImageCardUrl(this.equipment)}")`;
            this.logger.debug('openInfoEquipment', this.equipment);

            // Establecer datos de estadísticas dummy
            this.setDummyData();
          }
        })
      )
      .subscribe(() => {
        // Forzamos la detección de cambios.
        this.changeDetectorRef.detectChanges();
      });

    // this.mapEquipmentService
    //   .findById(equipmentMap.id)
    //   .pipe(takeUntil(this.unsubscribe$))
    //   .subscribe((equipment: Equipment) => {
    //     if (equipment) {

    //       const equipmentData = new MapEquipment(equipment);


    //       this.mapEquipmentService.findEquipmentStatistics(equipmentMap.id).
    //         pipe(takeUntil(this.unsubscribe$)).subscribe((statistics) => {

    //           equipmentData.statistics = statistics;

    //           this.equipment = equipmentData;

    //           this.backgroundUrl = 'url("' + StatusMapHelper.getImageCardUrl(this.equipment) + '")';

    //           console.log(this.equipment);

    //         });
    //     }

    //     // Tenemos que forzar la detección de cambios porque no los detecta.
    //     this.changeDetectorRef.detectChanges();
    //   });
  }

  private setDummyData() {
    if (this.equipment && this.equipment.statistics) {

      // this.equipment.accesory1 = '';
      // this.equipment.accesory2 = 'Maneta';
      // this.equipment.accesory3 = 'Pedal';
      // this.equipment.accesory4 = '';

      // this.equipment.description5 = 'Av/ As Corbaceiras 54, 36003';

      // this.equipment.device.modeStreetAt = new Date('2024-09-03T09:26:59.000+00:00');

      this.equipment.statistics.connection2gCountAt = new Date('2024-09-01T09:26:59.000+00:00');
      this.equipment.statistics.connectionNbiotCountAt = new Date('2024-09-03T09:26:59.000+00:00');

      this.equipment.statistics.accessCountDay1 = 12;
      this.equipment.statistics.accessCountDay2 = 19;
      this.equipment.statistics.accessCountDay3 = 11;
      this.equipment.statistics.accessCountDay4 = 23;
      this.equipment.statistics.accessCountDay5 = 7;
      this.equipment.statistics.accessCountDay6 = 9;
      this.equipment.statistics.accessCountDay7 = 12;

      this.equipment.statistics.accessCountInterval1 = 12;
      this.equipment.statistics.accessCountInterval2 = 19;
      this.equipment.statistics.accessCountInterval3 = 17;
      this.equipment.statistics.accessCountInterval4 = 24;
      this.equipment.statistics.accessCountInterval5 = 52;
      this.equipment.statistics.accessCountInterval6 = 20;

      this.equipment.statistics.connection2gCountDay1 = 2;
      this.equipment.statistics.connection2gCountDay2 = 4;
      this.equipment.statistics.connection2gCountDay3 = 3;
      this.equipment.statistics.connection2gCountDay4 = 6;
      this.equipment.statistics.connection2gCountDay5 = 7;
      this.equipment.statistics.connection2gCountDay6 = 1;
      this.equipment.statistics.connection2gCountDay7 = 2;
      this.equipment.statistics.connectionKoCountDay1 = 0;
      this.equipment.statistics.connectionKoCountDay2 = 2;
      this.equipment.statistics.connectionKoCountDay3 = 0;
      this.equipment.statistics.connectionKoCountDay4 = 0;
      this.equipment.statistics.connectionKoCountDay5 = 2;
      this.equipment.statistics.connectionKoCountDay6 = 1;
      this.equipment.statistics.connectionKoCountDay7 = 0;
      this.equipment.statistics.connectionNbiotCountDay1 = 8;
      this.equipment.statistics.connectionNbiotCountDay2 = 8;
      this.equipment.statistics.connectionNbiotCountDay3 = 4;
      this.equipment.statistics.connectionNbiotCountDay4 = 9;
      this.equipment.statistics.connectionNbiotCountDay5 = 2;
      this.equipment.statistics.connectionNbiotCountDay6 = 9;
      this.equipment.statistics.connectionNbiotCountDay7 = 6;

      this.equipment.statistics.quality2gCountDay1 = -65;
      this.equipment.statistics.quality2gCountDay2 = -102;
      this.equipment.statistics.quality2gCountDay3 = -102;
      this.equipment.statistics.quality2gCountDay4 = -102;
      this.equipment.statistics.quality2gCountDay5 = -102;
      this.equipment.statistics.quality2gCountDay6 = -102;
      this.equipment.statistics.quality2gCountDay7 = -102;
      this.equipment.statistics.qualityNbiotCountDay1 = -65;
      this.equipment.statistics.qualityNbiotCountDay2 = -54;
      this.equipment.statistics.qualityNbiotCountDay3 = -52;
      this.equipment.statistics.qualityNbiotCountDay4 = -82;
      this.equipment.statistics.qualityNbiotCountDay5 = -71;
      this.equipment.statistics.qualityNbiotCountDay6 = -62;
      this.equipment.statistics.qualityNbiotCountDay7 = -55;

    }
  }

  closeInfo() {
    this.equipment = undefined;

    this.closeEvent.emit();
    this.hideMoreInfoEvent.emit();
  }

  doClickClose() {
    this.closeInfo();
  }

  doClickMoreInfo() {
    this.showMoreInfoEvent.emit(this.equipment);
  }

  doClickHideModeInfo() {
    this.hideMoreInfoEvent.emit();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  calculateLastConnection() {
    return StatusMapHelper.calculateLastConnection(this.equipment?.statistics);
  }

  calculateAccesories() {
    const accesories = [];

    if (this.equipment) {
      if (this.equipment.accesory1 && this.equipment.accesory1.length > 0) {
        accesories.push(this.equipment.accesory1);
      }
      if (this.equipment.accesory2 && this.equipment.accesory2.length > 0) {
        accesories.push(this.equipment.accesory2);
      }
      if (this.equipment.accesory3 && this.equipment.accesory3.length > 0) {
        accesories.push(this.equipment.accesory3);
      }
      if (this.equipment.accesory4 && this.equipment.accesory4.length > 0) {
        accesories.push(this.equipment.accesory4);
      }
      if (this.equipment.accesory5 && this.equipment.accesory5.length > 0) {
        accesories.push(this.equipment.accesory5);
      }
    }

    return accesories.length > 0 ? accesories.join(', ') : '-';
  }

  calculateActiveDays() {
    if (this.equipment && this.equipment.device.modeStreetAt) {

      const currentDate = new Date();
      const diffInMilliseconds = currentDate.getTime() - this.equipment.device.modeStreetAt.getTime();
      const diffInDays = diffInMilliseconds / (1000 * 60 * 60 * 24);

      return Math.round(diffInDays);
    }

    return '-';
  }
}
