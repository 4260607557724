import { Injectable } from '@angular/core';
import { IMapEquipment } from '../models/map-equipment.interface';
import { Cluster, MarkerUtils } from '@googlemaps/markerclusterer';
import { MapEquipment } from '../models/map-equipment.model';
import { EquipmentModel } from '@configuration/equipment-models/models';
import { EquipmentType } from '@configuration/equipment-types/models';
import { EquipmentCategory } from '@configuration/equipment-categories/models';
import { IEquipmentStatistics } from '../models/equipment-statistics.interface';
import { STATS_CONNECTION_TYPE } from '../enums/connection-type.enum';

@Injectable()
export class StatusMapHelper {

  private static issuesDumped = [32, 35, 74, 29, 900, 60, 89, 1001];
  private static issuesNoBattery = [33, 21, 82, 61, 58, 320, 60, 89, 1001, 398, 540, 340];
  private static issuesLocked = [80, 81, 95, 2, 637, 321, 591, 786, 832, 918, 1003];
  private static issuesFire = [37, 97, 91];
  private static issuesDamageServo = [129, 310, 409, 602, 834];

  private static recommendationBattery = [78, 67, 2, 861, 238, 540];
  private static recommendationLocation = [74, 77, 350, 623];

  public static getIssuesForDataset(mapEquipment: IMapEquipment): string[] {

    const issueKeys: string[] = [];

    if (mapEquipment.issues) {

      mapEquipment.issues.forEach(issue => {
        issueKeys.push(issue.key);
      });

    }

    return issueKeys;
  }

  public static getRecommendationsForDataset(mapEquipment: IMapEquipment): string[] {

    const recommendationKeys: string[] = [];

    if (mapEquipment.recommendations) {

      mapEquipment.recommendations.forEach(recommendation => {
        recommendationKeys.push(recommendation.key);
      });

    }

    return recommendationKeys;
  }

  public static getIssuesMarkerHtml(cluster: Cluster) {
    let alertHtml = '';

    if (cluster.markers) {
      let issuesCounter = 0;
      let issueLastKey;

      for (let index = 0; index < cluster.markers.length; index++) {
        if (MarkerUtils.isAdvancedMarker(cluster.markers[index])) {
          const markerAdvanced = cluster.markers[index] as google.maps.marker.AdvancedMarkerElement;
          if (markerAdvanced.dataset['issues']) {
            const issueKeys: string[] = JSON.parse(markerAdvanced.dataset['issues']);
            if (issueKeys && issueKeys.length > 0) {
              issuesCounter += issueKeys.length;
              issueLastKey = issueKeys[0];
            }
          }
        }
      }

      if (issuesCounter > 0) {
        if (issuesCounter > 1) {
          alertHtml = issuesCounter.toString();
        } else {
          alertHtml = issueLastKey ? StatusMapHelper.getIconSVGByKey(issueLastKey) : '';
        }
      }
    }

    return alertHtml;
  }

  public static getRecommendationMarkerHtml(cluster: Cluster) {
    let recommendationHtml = '';

    if (cluster.markers) {
      let recommendationsCounter = 0;
      let recommendationLastKey;

      for (let index = 0; index < cluster.markers.length; index++) {
        if (MarkerUtils.isAdvancedMarker(cluster.markers[index])) {
          const markerAdvanced = cluster.markers[index] as google.maps.marker.AdvancedMarkerElement;
          if (markerAdvanced.dataset['recomendations']) {
            const recommendationKeys: string[] = JSON.parse(markerAdvanced.dataset['recomendations']);
            if (recommendationKeys && recommendationKeys.length > 0) {
              recommendationsCounter += recommendationKeys.length;
              recommendationLastKey = recommendationKeys[0];
            }
          }
        }
      }

      if (recommendationsCounter > 0) {
        if (recommendationsCounter > 1) {
          recommendationHtml = recommendationsCounter.toString();
        } else {
          recommendationHtml = recommendationLastKey ? StatusMapHelper.getIconSVGByKey(recommendationLastKey) : '';
        }
      }
    }

    return recommendationHtml;
  }

  public static getMarkerIconIssues(mapEquipment: IMapEquipment) {
    let issuesHtml = '';

    if (mapEquipment.issues && mapEquipment.issues.length > 0) {
      if (mapEquipment.issues.length > 1) {
        issuesHtml = mapEquipment.issues.length.toString();
      } else {
        issuesHtml = StatusMapHelper.getIconSVGByKey(mapEquipment.issues[0].key);
      }
    }

    return issuesHtml;
  }

  public static getMarkerIconRecommendations(mapEquipment: IMapEquipment) {
    let recommendationsHtml = '';

    if (mapEquipment.recommendations && mapEquipment.recommendations.length > 0) {
      if (mapEquipment.recommendations.length > 1) {
        recommendationsHtml = mapEquipment.recommendations.length.toString();
      } else {
        recommendationsHtml = StatusMapHelper.getIconSVGByKey(mapEquipment.recommendations[0].key);
      }
    }

    return recommendationsHtml;
  }

  public static getIconSVGByKey(key: string) {
    let iconSVGString = '';

    switch (key) {
      case 'no-battery':
        iconSVGString = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
          <path fill="currentColor" d="M19.2,55.4c-0.9-0.4-1.2-1.4-0.9-2.2L37.7,9.5c0.4-0.9,1.4-1.2,2.2-0.9c0.9,0.4,1.2,1.4,0.9,2.2L21.4,54.5
            C21.1,55.4,20,55.8,19.2,55.4z M15.3,45.7H3.4V27.2v-8.4h10.7h14.6c0.9,0,1.7-0.8,1.7-1.7c0-0.9-0.8-1.7-1.7-1.7H14.6H1.7
            c-0.9,0-1.7,0.8-1.7,1.7v9.7v20.6c0,0.9,0.8,1.7,1.7,1.7h13.6c0.9,0,1.7-0.8,1.7-1.7C17,46.4,16.3,45.7,15.3,45.7z M64,24.8v15
            c0,0.9-0.8,1.7-1.7,1.7h-3.2v6c0,0.9-0.8,1.7-1.7,1.7h-27c-0.9,0-1.7-0.8-1.7-1.7c0-0.9,0.8-1.7,1.7-1.7h25.3v-6V28.6v-3.8v-6h-2.6
            h-9.3c-0.9,0-1.7-0.8-1.7-1.7c0-0.9,0.8-1.7,1.7-1.7h8.9h4.7c0.8,0,1.5,0.6,1.7,1.4c0,0.1,0,0.2,0,0.3v6h3.2
            C63.2,23.1,64,23.8,64,24.8z M60.6,26.5h-1.5v2.1V38h1.5V26.5z"/>
          </svg>`;

        break;

      case 'communications':
        iconSVGString = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
          <path fill="currentColor" d="M34.5,5.3l-0.7,10.4c-0.1,0.8-0.8,1.5-1.6,1.5h-0.3c-0.8,0-1.5-0.7-1.6-1.5L29.5,5.3c0-0.4,0.1-0.8,0.4-1.1
            c0.3-0.3,0.6-0.5,1.1-0.5h2c0.4,0,0.8,0.2,1.1,0.5C34.4,4.5,34.5,4.9,34.5,5.3z M25.7,18.5l0.3-0.1c0.7-0.3,1.1-1.2,0.8-2l-3.6-9.8
            c-0.3-0.8-1.2-1.2-1.9-0.8l-1.9,0.8c-0.4,0.2-0.7,0.5-0.8,0.8c-0.1,0.4-0.1,0.8,0.1,1.1l4.9,9.2c0.3,0.5,0.8,0.8,1.4,0.8
            C25.3,18.6,25.5,18.6,25.7,18.5z M18.6,22.4c0.3,0.2,0.6,0.3,1,0.3c0.5,0,0.9-0.2,1.2-0.5l0.2-0.2c0.5-0.6,0.5-1.6,0-2.1l-7.2-7.5
            c-0.6-0.6-1.6-0.6-2.1,0l-1.4,1.5c-0.3,0.3-0.4,0.7-0.4,1.1c0,0.4,0.2,0.8,0.6,1L18.6,22.4z M5.6,26.2l10.1,2.5c0.1,0,0.2,0,0.4,0
            c0.7,0,1.3-0.4,1.5-1.1l0.1-0.3c0.3-0.8-0.2-1.6-0.9-1.9l-9.7-3.9c-0.8-0.3-1.7,0.1-1.9,0.9l-0.6,1.9c-0.1,0.4-0.1,0.8,0.1,1.1
            C4.9,25.9,5.3,26.1,5.6,26.2z M17.1,33.9l0-0.3c-0.1-0.8-0.8-1.4-1.6-1.4c0,0,0,0,0,0L5,32.6c-0.4,0-0.8,0.2-1,0.5
            c-0.3,0.3-0.4,0.7-0.3,1.1l0.2,2c0.1,0.7,0.7,1.3,1.4,1.3c0.1,0,0.2,0,0.3,0l10.3-1.8C16.6,35.5,17.1,34.7,17.1,33.9z M19.1,39.9
            l-0.2-0.3c-0.4-0.7-1.3-1-2.1-0.6l-9.4,4.6c-0.4,0.2-0.6,0.5-0.8,0.9c-0.1,0.4-0.1,0.8,0.1,1.1l1,1.8C8.1,47.8,8.6,48,9.1,48
            c0.3,0,0.6-0.1,0.8-0.3l8.6-5.8C19.2,41.5,19.5,40.6,19.1,39.9z M23,44.3c-0.6-0.5-1.6-0.3-2.1,0.3l-6.7,8c-0.3,0.3-0.4,0.7-0.3,1.1
            c0,0.4,0.3,0.7,0.6,1l1.7,1.2c0.3,0.2,0.5,0.3,0.9,0.3c0.5,0,1-0.3,1.2-0.7l5.5-8.8c0.4-0.7,0.2-1.6-0.4-2.1L23,44.3z M29,46.9
            l-0.3-0.1c-0.8-0.2-1.6,0.3-1.8,1.1L24,58c-0.1,0.4-0.1,0.8,0.1,1.1c0.2,0.3,0.5,0.6,0.9,0.7l2,0.4c0.1,0,0.2,0,0.3,0
            c0.7,0,1.3-0.5,1.4-1.2l1.5-10.3C30.4,47.9,29.8,47.1,29,46.9z M37.1,48c-0.2-0.8-1-1.3-1.8-1.1L35,46.9c-0.8,0.2-1.3,1-1.2,1.8
            L35.2,59c0.1,0.7,0.7,1.2,1.4,1.2c0.1,0,0.2,0,0.3,0l2-0.4c0.4-0.1,0.7-0.3,0.9-0.7c0.2-0.3,0.3-0.7,0.1-1.1L37.1,48z M43.1,44.5
            c-0.5-0.6-1.5-0.7-2.1-0.3l-0.3,0.2c-0.7,0.5-0.8,1.4-0.4,2.1l5.5,8.8c0.3,0.4,0.7,0.7,1.2,0.7c0.3,0,0.6-0.1,0.9-0.3l1.7-1.2
            c0.3-0.2,0.5-0.6,0.6-1c0-0.4-0.1-0.8-0.3-1.1L43.1,44.5z M56.5,43.5l-9.4-4.6c-0.7-0.3-1.7-0.1-2.1,0.6l-0.2,0.3
            c-0.4,0.7-0.2,1.6,0.5,2.1l8.6,5.8c0.2,0.2,0.5,0.3,0.8,0.3c0.5,0,1-0.3,1.2-0.7l1-1.8c0.2-0.4,0.3-0.8,0.1-1.1
            C57.2,44,56.9,43.7,56.5,43.5z M60.1,33c-0.3-0.3-0.6-0.5-1-0.5l-10.5-0.4c-0.8,0-1.5,0.6-1.6,1.4l0,0.3c-0.1,0.8,0.5,1.6,1.3,1.7
            l10.3,1.8c0.1,0,0.2,0,0.3,0c0.7,0,1.3-0.6,1.4-1.3l0.2-2C60.4,33.7,60.3,33.3,60.1,33z M46.3,27.4l0.1,0.3c0.2,0.6,0.8,1.1,1.5,1.1
            c0.1,0,0.2,0,0.4,0l10.1-2.5c0.4-0.1,0.7-0.3,0.9-0.7c0.2-0.4,0.2-0.8,0.1-1.1l-0.6-1.9c-0.3-0.8-1.1-1.2-1.9-0.9l-9.7,3.9
            C46.4,25.7,46,26.6,46.3,27.4z M43.1,22l0.2,0.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.7-0.1,1-0.3l8.2-6.4c0.3-0.3,0.5-0.6,0.6-1
            c0-0.4-0.1-0.8-0.4-1.1l-1.4-1.5c-0.5-0.6-1.5-0.6-2.1,0l-7.2,7.5C42.5,20.4,42.5,21.4,43.1,22z M38,18.4l0.3,0.1
            c0.2,0.1,0.4,0.1,0.6,0.1c0.6,0,1.1-0.3,1.4-0.8l4.9-9.2c0.2-0.4,0.2-0.8,0.1-1.1c-0.1-0.4-0.4-0.7-0.8-0.8l-1.9-0.8
            c-0.8-0.3-1.7,0.1-1.9,0.8l-3.6,9.8C36.9,17.2,37.2,18,38,18.4z"/>
          </svg>`;

        break;

      case 'dumped':
        iconSVGString = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
          <path fill="currentColor" d="M35.4,60.6c-1.7,0-3.5-0.4-5.3-1.2l-19.5-9c-5.5-2.8-7.7-8-6.5-14.9c0.8-4.7,3-11.8,4.6-17
            c0.6-1.9,1.2-3.6,1.5-4.5c0.8-2.4,2.3-4.6,4.3-6.3l0.2-0.1c0.4-0.2,0.7-0.5,1-0.7l0.3-0.2c0.7-0.5,1.3-0.8,2-1.1
            c1.1-0.5,2.3-0.9,3.6-1.3C21.5,4.1,22,4,22,4c0,0,0,0,0,0c1.7-0.4,3.5-0.6,5.4-0.6h0.1c4.8,0,10,1.2,14.9,3.4l0.3,0.1
            c8.5,3.9,15,10.6,16.9,17.5c1.1,4.1,0.6,8.2-1.4,11.6c-2.4,4-8.3,13.8-12.3,18.7C42.6,58.6,39.1,60.6,35.4,60.6z M17.7,9.2
            c-0.4,0.3-0.8,0.6-1.2,0.9c-1.5,1.3-2.6,3-3.2,4.8c-0.4,1.1-0.9,2.7-1.5,4.6c-1.6,5-3.7,12-4.5,16.5c-1,5.6,0.6,9.4,4.8,11.5
            l19.4,8.9c1.4,0.6,2.7,0.9,4,0.9c2.8,0,5.4-1.6,8.1-4.8c3.9-4.8,9.7-14.4,12.1-18.3c1.6-2.7,2-5.9,1.1-9.1
            c-1.7-6-7.5-11.9-15.1-15.4l-0.3-0.1c-4.5-2-9.2-3.1-13.6-3.1h-0.1c-1.9,0-3.6,0.2-5.2,0.6l-0.4,0.1c-0.9,0.2-1.8,0.6-2.6,1
            c-0.5,0.2-0.9,0.5-1.5,0.8C17.8,9.1,17.8,9.1,17.7,9.2C17.7,9.2,17.7,9.2,17.7,9.2z M40.2,44.8l4.4-4.4c0.7-0.7,0.7-1.8,0-2.4
            c-0.3-0.3-0.8-0.5-1.2-0.5c-0.5,0-0.9,0.2-1.2,0.5l-1.1,1.1c0,0-0.1,0.1-0.2,0.1c0,0,0,0-0.1,0c-0.1,0-0.2-0.1-0.2-0.2
            c-2-9.5-10.4-16.4-20.1-16.4c-0.9,0-1.7,0.8-1.7,1.7c0,0.9,0.8,1.7,1.7,1.7c7.7,0,14.5,5.2,16.5,12.6c0,0.1,0,0.2-0.1,0.3
            c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2-0.1l-1.4-1.4c-0.3-0.3-0.8-0.5-1.2-0.5c-0.5,0-0.9,0.2-1.2,0.5c-0.7,0.7-0.7,1.8,0,2.4l5,5
            c0.3,0.3,0.8,0.5,1.2,0.5C39.5,45.3,39.9,45.2,40.2,44.8z"/>
          </svg>`;

        break;

      case 'locked':
        iconSVGString = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
            <path fill="currentColor" d="M54.5,23.5v35.8c0,0.8-0.7,1.5-1.5,1.5H11c-0.8,0-1.5-0.7-1.5-1.5V23.5c0-0.8,0.7-1.5,1.5-1.5h6.8v-4.7
              c0-7.8,6.3-14.1,14.1-14.1c7.8,0,14.1,6.3,14.1,14.1V22H53C53.8,22,54.5,22.6,54.5,23.5z M20.9,22h22.2v-4.7
              c0-6.1-5-11.1-11.1-11.1c-6.1,0-11.1,5-11.1,11.1V22z M51.5,25h-39v32.8h39V25z M39.2,46.4c0.6,0.6,0.6,1.6,0,2.2
              c-0.3,0.3-0.7,0.5-1.1,0.5s-0.8-0.2-1.1-0.5l-4.9-5l-5,5c-0.3,0.3-0.7,0.5-1.1,0.5c-0.4,0-0.8-0.2-1.1-0.5c-0.6-0.6-0.6-1.6,0-2.2
              l4.9-4.9l-4.9-5c-0.6-0.6-0.6-1.6,0-2.2c0.6-0.6,1.6-0.6,2.2,0l5,4.9l4.9-4.9c0.6-0.6,1.6-0.6,2.2,0c0.6,0.6,0.6,1.6,0,2.2l-5,5
              L39.2,46.4z"/>
            </svg>`;

        break;

      case 'fire':
        iconSVGString = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
          <path fill="currentColor" d="M32.8,61.9c-1.3,0-2.8-0.2-4.7-0.5c-2.6-0.4-7.9-3-8-3
            c-2.4-1.4-4.5-3.1-6.3-5.2C9.5,48,7.6,41.9,8.3,35.1c0.3-3.2,1.4-6.4,3.1-9.3c0.3-0.5,0.6-0.7,1.1-0.7c0.6,0,0.9,0.3,1.2,0.8
            c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.1,0.4c0.3,0.7,0.6,1.4,1.2,2l0.3,0.3l-0.1-1.1c0-0.4-0.1-0.7-0.1-1.1c0-0.5,0-1,0.1-1.5
            c0.6-5.1,3.1-9,7.4-11.6c0.3-0.2,0.6-0.3,0.8-0.3c0.4,0,0.8,0.2,1.1,0.7c0.4,0.6,0.8,1.3,1.1,2c0.1,0.2,0.2,0.3,0.4,0.3
            c0.1,0,0.3-0.1,0.4-0.2c3.4-3.3,5-7.3,4.6-12.1c-0.1-0.9,0-1.5,0.7-1.9l1,0c5.3,3.2,7.6,7.9,7.1,14.3l0,0.3l0.2-0.2
            c0.6-0.4,1-1,1.4-1.5l0.2-0.3c0.1-0.1,0.2-0.2,0.2-0.3c0.1-0.1,0.2-0.2,0.3-0.3c0.3-0.4,0.7-0.6,1.1-0.6c0.4,0,0.8,0.2,1,0.6
            c1.5,2.3,2.5,4.8,2.9,7.6c0.3,2.3,0.2,4.5-0.3,6.7c0,0.1,0,0.1,0,0.2l0,0.4l0.2-0.1c1.2-0.6,2.2-1.4,3.1-2.2
            c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.2-0.2,0.2-0.2c0.3-0.3,0.7-0.5,1-0.5c0.5,0,0.9,0.3,1.2,0.8c1.1,2,1.9,4.3,2.4,6.6
            c0.5,2.4,0.6,4.9,0.3,7.6c-0.1,0.7-0.6,1.2-1.2,1.2c-0.1,0-0.1,0-0.2,0c-0.7-0.1-1.1-0.7-1-1.5c0.5-3.6,0-7.1-1.4-10.7
            c-0.1-0.2-0.2-0.5-0.5-0.5c-0.1,0-0.3,0.1-0.5,0.2c-1.6,1.2-3.4,2.2-5.4,2.9c-0.3,0.1-0.6,0.2-0.8,0.2c-0.3,0-0.6-0.1-0.9-0.4
            c-0.5-0.5-0.4-1-0.1-1.7c1.8-4.1,1.8-8.4,0.1-12.6c-0.2-0.5-0.3-0.7-0.5-0.7c-0.2,0-0.3,0.2-0.7,0.5C41.4,19,40,20.1,38.4,21
            c-0.4,0.2-0.8,0.4-1,0.4c-0.3,0-0.5-0.1-0.8-0.3c-0.5-0.4-0.5-0.9-0.2-1.8c1.6-3.8,1.4-7.5-0.7-11c-0.5-0.8-1.1-1.5-1.9-2.3
            l-0.2-0.2l0,0.3c-0.1,1.3-0.2,2.2-0.4,3c-0.8,3.3-2.3,6-4.5,8.3c-0.7,0.7-1.3,1.5-1.9,2.3c-0.3,0.5-0.7,0.7-1.1,0.7
            c-0.1,0-0.2,0-0.3,0c-0.5-0.1-0.9-0.4-1-1.1c-0.2-0.9-0.6-1.6-0.9-2.4c-0.1-0.2-0.2-0.4-0.5-0.4c-0.1,0-0.3,0.1-0.5,0.2
            c-2.6,2.1-4.2,4.8-4.7,8c-0.4,2.5,0,4.9,1.1,7.2c0.3,0.7,0.2,1.2-0.2,1.6c-0.2,0.2-0.5,0.3-0.8,0.3c-0.3,0-0.5-0.1-0.8-0.2
            c-1.6-0.9-3-2.1-4.1-3.6c0,0,0-0.1-0.1-0.1c-0.1-0.1-0.2-0.3-0.4-0.3c-0.3,0.1-0.4,0.3-0.4,0.5c0,0,0,0.1,0,0.1
            c-2.6,7.4-1.6,14.3,2.9,20.5c1.3,1.7,2.9,3.3,4.9,4.7c0.1,0,0.1,0.1,0.2,0.1l0.7,0.4l-0.2-0.4c-1-1.7-1.6-3.1-1.9-4.5
            c-0.7-3.1-0.3-6.2,1.2-9.1c0.3-0.7,0.7-1,1.2-1c0.4,0,0.8,0.2,1.2,0.6c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.2,0.3,0.5,0.3l0.2,0l0-0.2
            c-0.1-0.9-0.1-1.9,0.1-2.9c0.2-1.2,0.6-2.3,1.2-3.4c0.5-0.8,0.8-1.1,1.3-1.1c0.3,0,0.7,0.1,1.2,0.4l0.2,0.1l0-0.2
            c0.3-2.1,1.2-3.9,2.7-5.3c0.4-0.3,0.8-0.6,1.3-1c0.3-0.2,0.5-0.3,0.8-0.3c0.2,0,0.4,0.1,0.7,0.2c0.4,0.3,0.6,0.7,0.5,1.3
            c-0.2,2,0.3,3.8,1.5,5.4c0.2,0.2,0.3,0.4,0.5,0.4c0.2,0,0.4-0.2,0.5-0.4c0.3-0.5,0.7-0.7,1.1-0.7c0.3,0,0.6,0.1,0.9,0.3
            c2.1,1.3,3.5,3.2,4,5.6c0.1,0.3,0.1,0.7,0.5,0.7c0.1,0,0.2,0,0.4-0.1c0.1,0,0.2-0.1,0.4-0.1c0.4,0,0.7,0.2,1,0.6
            c0.9,1.6,1.5,3.4,1.8,5.3c0.4,3.1-0.3,6-2,8.9l-0.2,0.4l0.4-0.2c1-0.5,1.8-1.2,2.6-1.9c0.9-0.8,1.7-1.7,2.5-2.7
            c0.3-0.4,0.7-0.7,1.1-0.7c0.3,0,0.5,0.1,0.8,0.3c0.6,0.5,0.6,1.2,0,1.9c-2,2.5-4.4,4.6-7.1,6.1c-0.1,0.1-0.2,0.1-0.3,0.2l-0.1,0.1
            c0,0-4.6,2.3-6.7,2.7l-0.3,0.1C35.2,61.7,34.2,61.9,32.8,61.9z M21.8,44.3c-0.3,0-0.4,0.4-0.5,0.6c-0.4,1.6-0.5,3-0.3,4.5
            c0.6,4.7,4.7,9.6,11,9.6c3.4,0,6.3-1.3,8.5-4c2.3-2.8,3.1-6,2.3-9.7c0-0.2-0.1-0.5-0.4-0.5c-0.1,0-0.3,0.1-0.5,0.3
            c-0.4,0.4-0.9,0.7-1.4,1c-0.3,0.2-0.6,0.3-0.9,0.3c-0.3,0-0.5-0.1-0.8-0.3c-0.5-0.4-0.5-0.9-0.2-1.6c0.5-1,0.6-2.2,0.5-3.3
            c-0.1-1.4-0.7-2.6-1.7-3.6c-0.1-0.2-0.3-0.2-0.4-0.2c-0.3,0-0.3,0.3-0.3,0.4c0,0.2-0.1,0.3-0.1,0.5c-0.2,0.5-0.7,0.8-1.2,0.8
            c-0.4,0-0.7-0.2-0.9-0.4L34,38.1c-1.5-1.7-3-3.4-3.5-5.7L30.5,32l-0.2,0.4c0,0.1-0.1,0.2-0.1,0.2c-0.8,1.6-0.8,3.3-0.1,5.1
            c0.3,0.7,0.2,1.2-0.3,1.6c-0.2,0.2-0.5,0.3-0.8,0.3c-0.2,0-0.5-0.1-0.8-0.3c-0.5-0.3-1-0.6-1.6-1.1c-0.3-0.2-0.4-0.3-0.5-0.3
            c-0.2,0-0.3,0.3-0.4,0.6c-0.5,1.7-0.3,3.5,0.5,5.2c0.3,0.6,0.2,1.1-0.2,1.5c-0.3,0.3-0.5,0.4-0.9,0.4c-0.2,0-0.4,0-0.6-0.1
            c-0.9-0.3-1.6-0.6-2.2-1C22.1,44.4,21.9,44.3,21.8,44.3z M52.9,47.6c0.3,0,0.6-0.1,0.9-0.3c0.2-0.2,0.4-0.6,0.4-0.9
            c0-0.7-0.6-1.2-1.2-1.2c-0.7,0-1.2,0.6-1.2,1.2C51.7,47,52.2,47.6,52.9,47.6L52.9,47.6z"/>
          </svg>`;

        break;

      case 'damage-servo':
        iconSVGString = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
          <path fill="currentColor" d="M55.5,35.6c-0.5-0.3-1-0.6-1.5-0.9c-0.1,0-0.1-0.1-0.1-0.1
            c0,0,0-0.1,0-0.2c0.2-1.6,0.2-3.2,0-4.8c0-0.1,0-0.2,0-0.2c0,0,0.3-0.2,0.3-0.2c0.5-0.3,1-0.6,1.4-0.9c1.9-1.1,2.7-3.2,1.8-5.3
            c-0.4-0.9-0.7-1.7-1.1-2.6c-0.7-1.6-2.1-2.7-3.8-2.7c-0.4,0-0.8,0.1-1.3,0.2c-0.6,0.2-1.2,0.3-1.8,0.5c-1.1-1.3-2.3-2.5-3.7-3.7
            c0.1-0.7,0.3-1.3,0.5-1.8c0.6-2.2-0.4-4.3-2.5-5.1c-0.9-0.3-1.7-0.7-2.6-1.1c-0.6-0.3-1.2-0.4-1.8-0.4c-0.7,0-2.5,0.2-3.6,2.3
            c-0.2,0.5-0.5,0.9-0.8,1.4c0,0-0.2,0.3-0.2,0.3c0,0,0,0,0,0c-0.1,0-0.1,0-0.2,0c-0.8-0.1-1.6-0.1-2.4-0.1c-0.8,0-1.6,0.1-2.4,0.2
            c-0.1,0-0.1,0-0.1,0c0,0-0.1,0-0.1-0.2c-0.3-0.5-0.7-1.1-1-1.6c-0.7-1.3-2-2.1-3.5-2.1c-0.6,0-1.1,0.1-1.7,0.4
            c-0.9,0.4-1.8,0.8-2.7,1.1c-2.1,0.8-3.1,2.9-2.5,5.1c0.1,0.4,0.2,0.8,0.3,1.2c0,0,0.2,0.7,0.2,0.7c0,0-0.1,0-0.1,0.1
            c-1,0.9-2.3,1.9-3.4,3.3c-0.2,0.2-0.2,0.3-0.3,0.3c0,0,0,0,0,0c0,0-0.2,0-0.5-0.1c-0.8-0.2-1.6-0.5-2.5-0.5c-3,0-3.9,2.4-4.5,3.9
            c-1.5,3.6-0.8,5.5,2.5,7.4c0.2,0.1,0.4,0.2,0.5,0.3c0,0.1,0,0.2,0,0.5c-0.2,1.4-0.2,2.8,0,4.2c0,0.2,0,0.4,0,0.5
            c-0.1,0.1-0.2,0.2-0.4,0.3L9.6,35c-0.4,0.2-0.7,0.4-1.1,0.6c-2,1.1-2.8,3.3-1.8,5.4c0.3,0.7,0.7,1.5,1.1,2.5
            c0.7,1.7,2.1,2.7,3.8,2.7c0.4,0,0.9-0.1,1.3-0.2c0.6-0.2,1.3-0.3,1.9-0.5c0,0,0,0,0.1,0c0.9,1.1,1.9,2.3,3.3,3.3
            c0.3,0.2,0.3,0.3,0.4,0.3c0,0,0,0.2-0.1,0.7c-1.2,4,0.4,5.6,3.2,6.8c1.1,0.5,2,0.8,3.1,0.8c2.6,0,3.8-2.1,4.5-3.4
            c0.1-0.2,0.2-0.3,0.2-0.3c0,0,0.1,0,0.2,0c0.1,0,0.2,0,0.3,0c0.7,0.1,1.4,0.1,2.1,0.1c0.7,0,1.4-0.1,2.1-0.2c0.1,0,0.2,0,0.4,0
            c0.1,0,0.1,0,0.1,0c0,0,0.1,0.1,0.1,0.2c0.2,0.4,0.4,0.7,0.6,1c0.1,0.2,0.2,0.3,0.3,0.5c1.1,2,2.8,2.2,3.5,2.2
            c0.6,0,1.2-0.1,1.7-0.4c0.9-0.4,1.7-0.8,2.6-1.1c2.1-0.8,3.2-2.9,2.5-5.1c-0.2-0.6-0.3-1.2-0.5-1.8c1.3-1.1,2.5-2.3,3.7-3.7
            c0.2,0.1,0.4,0.1,0.6,0.2c0.4,0.1,0.8,0.2,1.3,0.3c0.4,0.1,0.9,0.2,1.3,0.2c1.3,0,3-0.7,3.8-2.7c0.3-0.9,0.7-1.8,1.1-2.6
            C58.2,38.9,57.5,36.7,55.5,35.6z M54.4,39.6c-0.4,0.9-0.8,1.8-1.1,2.7C53.2,42.5,53,43,52.5,43c-0.1,0-0.2,0-0.4-0.1
            c-0.5-0.1-1-0.3-1.4-0.4c-0.2-0.1-0.5-0.1-0.7-0.2c-0.3-0.1-0.6-0.1-0.8-0.1c-0.9,0-1.6,0.4-2.3,1.1c-1.1,1.3-2.2,2.4-3.4,3.4
            c-1,0.8-1.3,1.8-1,3c0.2,0.7,0.3,1.4,0.5,2.1c0.2,0.6,0,1-0.6,1.2c-0.9,0.4-1.8,0.7-2.7,1.2c-0.2,0.1-0.3,0.1-0.4,0.1
            c-0.4,0-0.6-0.3-0.7-0.5c-0.1-0.2-0.3-0.4-0.4-0.6c-0.2-0.3-0.3-0.5-0.5-0.8c-0.7-1.3-1.7-2-3.1-2c-0.3,0-0.5,0-0.8,0.1
            c-0.5,0.1-1.1,0.1-1.7,0.1c-0.6,0-1.1,0-1.7-0.1c-0.3,0-0.5-0.1-0.8-0.1c-1.4,0-2.5,0.6-3.2,2c-0.6,1.1-1,1.8-1.7,1.8
            c-0.4,0-1-0.2-1.9-0.6c-1.8-0.8-2-0.9-1.4-2.8c0.6-2,0.2-3.4-1.4-4.6c-1.1-0.8-1.9-1.8-2.7-2.7c-0.7-0.8-1.5-1.2-2.7-1.3l-0.6,0.1
            c-0.7,0.2-1.4,0.3-2.1,0.5c-0.1,0-0.3,0.1-0.4,0.1c-0.5,0-0.7-0.4-0.8-0.7c-0.4-1.1-0.8-1.9-1.1-2.7c-0.3-0.5-0.1-0.9,0.4-1.2
            c0.4-0.2,0.8-0.5,1.2-0.7l0.2-0.1c1.7-1,2.2-2.1,1.9-4c-0.2-1.1-0.2-2.2,0-3.3c0.3-2-0.2-3-2-4.1c-2-1.1-2-1.3-1.2-3.4
            c0.5-1.3,0.8-1.9,1.5-1.9c0.4,0,0.8,0.1,1.5,0.3c0.5,0.2,1,0.2,1.4,0.2c1.1,0,2.1-0.5,2.9-1.6c0.8-1.1,1.9-2,2.8-2.8
            c1.1-0.9,1.5-2,1.1-3.4l-0.2-0.6c-0.1-0.5-0.2-0.9-0.4-1.4c-0.2-0.6,0-1,0.5-1.2c1-0.4,1.9-0.8,2.8-1.2c0.2-0.1,0.3-0.1,0.4-0.1
            c0.4,0,0.6,0.3,0.7,0.5c0.3,0.6,0.7,1.2,1,1.8c0.7,1.1,1.7,1.7,2.9,1.7c0.2,0,0.4,0,0.6,0c0.7-0.1,1.3-0.1,2-0.1
            c0.7,0,1.4,0,2.1,0.1c0.2,0,0.4,0,0.6,0c1.2,0,2.2-0.5,2.8-1.6l0.1-0.2c0.3-0.5,0.6-1,0.9-1.5c0.1-0.2,0.4-0.6,0.8-0.6
            c0.1,0,0.3,0,0.5,0.1c0.9,0.4,1.8,0.8,2.7,1.1c0.6,0.2,0.8,0.6,0.6,1.2c-0.2,0.7-0.4,1.4-0.5,2.1c-0.3,1.2,0.1,2.2,0.9,2.9
            c1.3,1.2,2.5,2.3,3.6,3.6c0.6,0.7,1.3,1,2.2,1c0.2,0,0.5,0,0.7-0.1c0.7-0.1,1.4-0.3,2.1-0.5c0.2,0,0.3-0.1,0.4-0.1
            c0.4,0,0.7,0.2,0.8,0.6c0.4,0.9,0.7,1.9,1.1,2.8c0.2,0.5,0.1,0.9-0.4,1.2c-0.5,0.3-1,0.6-1.6,0.9l-0.2,0.1c-1.3,0.8-1.8,1.9-1.6,3.5
            c0.2,1.3,0.2,2.6,0,4c-0.2,1.5,0.3,2.6,1.6,3.4c0.6,0.3,1.1,0.7,1.7,1C54.3,38.6,54.7,38.9,54.4,39.6z M40.8,23.2
            c-2.3-2.4-5.5-3.6-8.8-3.7c-6.9,0-12.5,5.6-12.5,12.4c0,6.8,5.6,12.4,12.4,12.4h0c6.9,0,12.4-5.6,12.5-12.4
            C44.4,28.7,43.1,25.6,40.8,23.2z M32,41.2L32,41.2c-5.1,0-9.2-4.1-9.2-9.2c0-5.1,4.1-9.2,9.2-9.2c2.5,0,4.8,1,6.5,2.7
            c1.7,1.7,2.7,4.1,2.7,6.5C41.2,37.1,37.1,41.2,32,41.2z"/>
          </svg>`;

        break;

      case 'open-cover':
        iconSVGString = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
          <path fill="currentColor" d="M52,24.7h-6.5v-9c0-7.4-6-13.5-13.5-13.5c-7.3,0-13.3,5.9-13.4,13.1c0.1,0.7,0.7,1.3,1.4,1.3
            c0.8,0,1.4-0.6,1.4-1.4v0.5c0-5.8,4.7-10.6,10.6-10.6c5.8,0,10.6,4.7,10.6,10.6v9H12c-0.8,0-1.4,0.6-1.4,1.4v34.1
            c0,0.8,0.6,1.5,1.4,1.5H52c0.8,0,1.4-0.6,1.4-1.5V26.2C53.4,25.4,52.8,24.7,52,24.7z M50.5,58.8h-37V27.6h37V58.8z"/>
          </svg>`;

        break;

      case 'change-battery':
        iconSVGString = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
          <path fill="currentColor" d="M28.8,19H14.7H4.3v8.1V45h11.5c0.9,0,6.9,0.6,6.9,1.5c0,0.9-6,1.8-6.9,1.8H2.7c-0.9,0-1.7-0.7-1.7-1.7v-20
            v-9.4c0-0.9,0.7-1.7,1.7-1.7h12.4h13.7 M28.4,15.7H52h4.6c0.8,0,1.5,0.6,1.6,1.3c0,0.1,0,0.2,0,0.3v5.8h3.1c0.9,0,1.7,0.7,1.7,1.7
            v14.5c0,0.9-0.7,1.7-1.7,1.7h-3.1v5.8c0,0.9-0.7,1.7-1.7,1.7H14.5c-0.9,0-1.6-0.7-1.6-1.7c0-0.9,0.7-1.7,1.6-1.7H55v-5.8V28.5v-3.7
            V19h-2.5h-24 M59.7,26.4h-1.4v2v9.1h1.4V26.4z"/>
          </svg>`;

        break;

      case 'change-location':
        iconSVGString = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
          <path fill="currentColor" d="M32,56.5c0,0-0.1,0-0.1,0c-0.5,0-1.1-0.3-1.4-0.8c-0.5-0.9-12.6-21-15.9-28.5c-0.9-2.1-1-4.3-1-6
            c0-10.1,8.2-18.4,18.4-18.4c10.1,0,18.4,8.2,18.4,18.4c0,1.7-0.1,4-1,6C46,34.7,33.9,54.9,33.4,55.8C33.1,56.3,32.5,56.5,32,56.5z
            M31.9,6.1c-8.4,0-15.2,6.8-15.2,15.2c0,2.1,0.2,3.6,0.8,4.7c2.6,5.8,11.2,20.5,14.5,26c3.3-5.5,11.9-20.1,14.5-26
            c0.5-1.2,0.8-2.6,0.8-4.7C47.2,12.9,40.3,6.1,31.9,6.1z M31.9,33.5c-6.9,0-12.5-5.6-12.5-12.5S25,8.5,31.9,8.5
            c6.9,0,12.5,5.6,12.5,12.5S38.8,33.5,31.9,33.5z M31.9,11.7c-5.1,0-9.3,4.2-9.3,9.3c0,5.1,4.2,9.3,9.3,9.3c5.1,0,9.3-4.2,9.3-9.3
            C41.3,15.9,37.1,11.7,31.9,11.7z M51.2,55.5c0-2.4-3-4-9.2-4.9c-0.9-0.1-1.7,0.5-1.8,1.3c-0.1,0.9,0.5,1.7,1.3,1.8
            c3.8,0.6,5.6,1.4,6.3,1.8c-1.4,1-6.8,2.4-15.8,2.4s-14.4-1.5-15.8-2.4c0.7-0.4,2.5-1.2,6.3-1.8c0.9-0.1,1.5-0.9,1.3-1.8
            c-0.1-0.9-0.9-1.4-1.8-1.3c-6.2,0.9-9.2,2.5-9.2,4.9c0,5.4,17.2,5.6,19.2,5.6C34,61.1,51.2,60.9,51.2,55.5z"/>
          </svg>`;

        break;

      default:
        break;
    }

    return iconSVGString;
  }

  public static setDummyData(equipmentMapList: IMapEquipment[]) {
    equipmentMapList.forEach((equipmentMap) => {
      // Establecemos datos a mano.

      StatusMapHelper.setDummyDataItem(equipmentMap);
    });

    return equipmentMapList;
  }

  public static setDummyDataItem(equipmentMap: IMapEquipment): IMapEquipment {
    const startDate = new Date('2024-05-17');
    const endDate = new Date('2024-05-30');

    if (StatusMapHelper.issuesDumped.includes(equipmentMap.id)) {
      equipmentMap.alertDumped = true;
      equipmentMap.alertDumpedAt = StatusMapHelper.getRandomDate(startDate, endDate);
    }

    if (StatusMapHelper.issuesNoBattery.includes(equipmentMap.id)) {
      equipmentMap.alertNoBattery = true;
      equipmentMap.alertNoBatteryAt = StatusMapHelper.getRandomDate(startDate, endDate);
    }

    if (StatusMapHelper.issuesLocked.includes(equipmentMap.id)) {
      equipmentMap.alertLocked = true;
      equipmentMap.alertLockedAt = StatusMapHelper.getRandomDate(startDate, endDate);
    }

    if (StatusMapHelper.issuesFire.includes(equipmentMap.id)) {
      equipmentMap.alertFire = true;
      equipmentMap.alertFireAt = StatusMapHelper.getRandomDate(startDate, endDate);
    }

    if (StatusMapHelper.issuesDamageServo.includes(equipmentMap.id)) {
      equipmentMap.alertDamageServo = true;
      equipmentMap.alertDamageServoAt = StatusMapHelper.getRandomDate(startDate, endDate);
    }

    if (StatusMapHelper.recommendationBattery.includes(equipmentMap.id)) {
      equipmentMap.recomendationChangeBattery = true;
      equipmentMap.recomendationChangeBatteryAt = StatusMapHelper.getRandomDate(startDate, endDate);
    }

    if (StatusMapHelper.recommendationLocation.includes(equipmentMap.id)) {
      equipmentMap.recomendationChangeLocation = true;
      equipmentMap.recomendationChangeLocationAt = StatusMapHelper.getRandomDate(startDate, endDate);
    }

    return equipmentMap;
  }

  public static setDummyDataItemMap(equipmentMap: MapEquipment): MapEquipment {
    const startDate = new Date('2024-05-17');
    const endDate = new Date('2024-05-30');

    if (StatusMapHelper.issuesDumped.includes(equipmentMap.id)) {
      equipmentMap.alertDumped = true;
      equipmentMap.alertDumpedAt = StatusMapHelper.getRandomDate(startDate, endDate);
    }

    if (StatusMapHelper.issuesNoBattery.includes(equipmentMap.id)) {
      equipmentMap.alertNoBattery = true;
      equipmentMap.alertNoBatteryAt = StatusMapHelper.getRandomDate(startDate, endDate);
    }

    if (StatusMapHelper.issuesLocked.includes(equipmentMap.id)) {
      equipmentMap.alertLocked = true;
      equipmentMap.alertLockedAt = StatusMapHelper.getRandomDate(startDate, endDate);
    }

    if (StatusMapHelper.issuesFire.includes(equipmentMap.id)) {
      equipmentMap.alertFire = true;
      equipmentMap.alertFireAt = StatusMapHelper.getRandomDate(startDate, endDate);
    }

    if (StatusMapHelper.issuesDamageServo.includes(equipmentMap.id)) {
      equipmentMap.alertDamageServo = true;
      equipmentMap.alertDamageServoAt = StatusMapHelper.getRandomDate(startDate, endDate);
    }

    if (StatusMapHelper.recommendationBattery.includes(equipmentMap.id)) {
      equipmentMap.recomendationChangeBattery = true;
      equipmentMap.recomendationChangeBatteryAt = StatusMapHelper.getRandomDate(startDate, endDate);
    }

    if (StatusMapHelper.recommendationLocation.includes(equipmentMap.id)) {
      equipmentMap.recomendationChangeLocation = true;
      equipmentMap.recomendationChangeLocationAt = StatusMapHelper.getRandomDate(startDate, endDate);
    }

    return equipmentMap;
  }

  /** IMAGES */

  public static getImageMarkerUrl(equipment: IMapEquipment) {

    let imageName = '';
    const basePath = './assets/img/status-map/equipments/markers/';
    const defaultImage = 'default.png';

    const paths = new Map<string, string[]>();

    paths.set('1', ['bigbin-29']);
    paths.set('3', ['bigbin-29']);
    paths.set('4', ['city-bin']);
    paths.set('6', ['back-bin']);
    paths.set('9', ['under-bin']);
    paths.set('2', ['small-bin']);

    if (paths.has(equipment.equipmentModelId.toString())) {
      const splits = paths.get(equipment.equipmentModelId.toString());
      if (splits) {
        imageName = splits.join('-') + '.png';
      }
    } else {
      imageName = defaultImage;
    }

    return basePath + imageName;
  }


  public static getImageCardUrl(equipment: MapEquipment) {

    let imageName = '';
    const basePath = './assets/img/status-map/equipments/cards/';
    const defaultImage = 'default.png';

    const paths = new Map<string, string[]>();

    paths.set('1', ['bigbin-29']);
    paths.set('3', ['bigbin-29']);
    paths.set('4', ['city-bin']);
    paths.set('6', ['back-bin']);
    paths.set('9', ['under-bin']);
    paths.set('2', ['small-bin']);

    if (paths.has(equipment.equipmentModel.id.toString())) {
      const splits = paths.get(equipment.equipmentModel.id.toString());
      if (splits) {
        imageName = splits.join('-') + '.png';
      }
    } else {
      imageName = defaultImage;
    }

    return basePath + imageName;
  }


  public static string_to_slug(str: string) {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;';
    const to = 'aaaaeeeeiiiioooouuuunc------';
    for (let i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str
      .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
      .replace(/\s+/g, '-') // collapse whitespace and replace by -
      .replace(/-+/g, '-'); // collapse dashes

    return str;
  }

  public static getRandomDate(start: Date, end: Date): Date {
    // Convertir las fechas a milisegundos
    const startMs = start.getTime();
    const endMs = end.getTime();

    // Generar un número aleatorio entre el inicio y el fin
    const randomMs = startMs + Math.random() * (endMs - startMs);

    // Crear una nueva fecha con el número aleatorio de milisegundos
    return new Date(randomMs);
  }

  public static createPathCombinations(equipmentModels: EquipmentModel[], equipmentTypes: EquipmentType[], equipmentCategories: EquipmentCategory[]) {
    const paths = new Map<string, string[]>();

    equipmentModels.forEach((model) => {
      if (model.name) {
        let key = model.id.toString();
        const modeName = StatusMapHelper.string_to_slug(model.name);

        paths.set(key, [modeName]);

        equipmentTypes.forEach((type) => {
          if (type.name) {
            key = model.id.toString() + '-' + type.id.toString();
            const typeName = StatusMapHelper.string_to_slug(type.name);

            paths.set(key, [modeName, typeName]);

            equipmentCategories.forEach((category) => {
              if (category.name) {
                key = model.id.toString() + '-' + type.id.toString() + '-' + category.id.toString();

                paths.set(key, [modeName, typeName, StatusMapHelper.string_to_slug(category.name)]);
              }
            });
          }
        });
      }
    });
  }

  public static serializeMapToJson(map: Map<string, unknown>) {
    const mapArray = Array.from(map.entries());

    return JSON.stringify(mapArray);
  }

  public static deserializeMapFromJson(jsonString: string) {
    const parsedArray = JSON.parse(jsonString);

    return new Map(parsedArray);
  }

  public static calculateLastConnection(stats: IEquipmentStatistics | undefined) {
    const connection2g = stats?.connection2gCountAt;
    const connectionNbiot = stats?.connectionNbiotCountAt;

    if (!stats || (!connection2g && !connectionNbiot)) {
        return '-';
    }

    if (connection2g && connectionNbiot) {
        return connection2g.getTime() > connectionNbiot.getTime() ? STATS_CONNECTION_TYPE.TYPE_2G : STATS_CONNECTION_TYPE.TYPE_NBIOT;
    }

    return connection2g ? STATS_CONNECTION_TYPE.TYPE_2G : STATS_CONNECTION_TYPE.TYPE_NBIOT;
  }
}
