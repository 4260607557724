import { Component } from '@angular/core';

@Component({
  selector: 'lockbin-status-map',
  templateUrl: './status-map.component.html',
  styles: [
  ]
})
export class StatusMapComponent {

}
