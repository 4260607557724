
// Angular modules
import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { IFilter } from '../../models/filter.interface';
import { EventBusService } from '@services/event-bus.service';
import { BUS_EVENT_MAP_TYPE } from '../../enums/bus-event-map-type.enum';
import { IFilterItem } from '../../models/filter-item.interface';

@Component({
  selector: 'lockbin-map-filter-item',
  templateUrl: './map-filter-item.component.html',
  // styleUrls: ['./map-filter-item.component.scss'],
})
export class MapFilterItemComponent implements OnInit {

  @HostBinding('class') componentCssClass = 'map-filter-item';

  @Input() filter: IFilter | undefined;
  @Output() appliedFilter = new EventEmitter<IFilterItem>();
  @Output() removedFilter = new EventEmitter<IFilterItem>();

  showOptions = false;

  constructor(protected logger: NGXLogger, protected eventBusService: EventBusService) {}

  ngOnInit(): void {

    this.logger.debug('MapFilterItemComponent:ngOnInit');

    this.eventBusService.on(BUS_EVENT_MAP_TYPE.CLEAR_FILTERS, () => {
      this.clearFilters();
    });

    this.eventBusService.on(BUS_EVENT_MAP_TYPE.REMOVE_FILTER, (filterItem: IFilterItem) => {
      this.removeFilterItem(filterItem);
    });

  }

  doToggleOptions() {
    this.showOptions = ! this.showOptions;
  }

  closeOptions() {
    this.showOptions = false;
  }

  doClickFilter(filterItem: IFilterItem) {

    if (filterItem.selected) {

      filterItem.selected = false;

      this.removeFilterItem(filterItem);

      this.removedFilter.emit(filterItem);

    } else {

      filterItem.selected = true;

      this.appliedFilter.emit(filterItem);

    }

  }

  removeFilterItem(filterItem: IFilterItem) {

    this.filter?.items.forEach((item) => {
      if(item.class === filterItem.class && item.value === filterItem.value) {
        item.selected = false;
      }
    });

  }

  clearFilters() {

    this.filter?.items.forEach((item) => {
      item.selected = false;
    });

    this.closeOptions();

  }
}
