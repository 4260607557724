import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'lockbin-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'lockbin-backend-portal';
  currentUrl: string | undefined;

  constructor(private router: Router) {

    console.log('Iniciando desde App.component.ts. Mensaje que sólo debería mostrarse en DESARROLLO');

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.urlAfterRedirects;
        console.log(event); // Para depurar, ver el objeto completo de NavigationEnd
      }
    });
  }

}
