<div class="map-container"
  [class.is-compact-sidebar]="sidebarCompact"
  [class.is-show-equipment-info]="showEquipmentInfo"
  [class.is-show-equipment-info-extend]="showEquipmentExtendInfo"
  [class.map-loaded]="googleMapApiLoaded">

  <div class="map-sidebar">

    <lockbin-map-sidebar-header [headerData]="headerData"></lockbin-map-sidebar-header>

    <img class="map-sidebar-separator" src="assets/img/status-map/map-sidebar-sep.png" width="320" alt="">

    <ngx-simplebar class="map-sidebar-content" [options]="optionsSimpleBarSidebar">

      <div style="height: 12px;"></div>

      <div class="px-3 mb-3">
        <lockbin-map-issues
          [issues]="issues"
          (applyIssue)="doApplyIssue($event)"
          (removeIssue)="doRemoveIssue()"></lockbin-map-issues>
      </div>

      <div class="px-3 mb-3">
        <lockbin-map-recommendations
          [recommendations]="recommendations"
          (applyRecommendation)="doApplyRecommendation($event)"
          (removeRecommendation)="doRemoveRecommendation()"></lockbin-map-recommendations>
      </div>

      <div class="px-3">
        <lockbin-map-filter></lockbin-map-filter>
      </div>

      <div class="py-3"></div>

    </ngx-simplebar>

  </div>

  <button class="equipment-card__info__open" aria-label="Abrir equipo" (click)="doOpenExtendedEquipmentInfo()">
    <img src="./assets/img/status-map/icon-open.png" width="43" height="115" class="img-fluid"/>
  </button>

  <lockbin-equipment-info
    [showEquipmentExtendInfo]="showEquipmentExtendInfo"
    (closeEvent)="doCloseEquipmentInfo()"
    (showMoreInfoEvent)="doMoreInfoEquipment($event)"
    (hideMoreInfoEvent)="doHideMoreInfoEquipment()"></lockbin-equipment-info>

  <lockbin-equipment-info-extend (closeEvent)="doHideMoreInfoEquipment()"></lockbin-equipment-info-extend>

  <div class="status-map__brand">
    <img class="status-map__brand__image" width="250" src="assets/img/logo-fv-des-short.png" alt="Formato Verde">
  </div>

  <div class="status-map">

    <div #mapEquipment
      class="status-map__container"
      [class.disable-recommendations]="disableRecommendations"
      [class.disable-issues]="disableIssues"></div>

    <div #mapTypeControl class="map-type-control" (click)="toggleMapType()">
      <svg class="map-type-control__source" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 150">
        <path fill="currentColor" d="M150,75c0-4.5-2.4-8.6-6.3-10.7l-4.7-2.4l4.6-2.4c0,0,0,0,0,0c3.9-2,6.3-6.1,6.3-10.6
          c0-4.5-2.4-8.6-6.3-10.7l-60-31.7C81,5,78,4.3,75,4.3c-3,0-6,0.7-8.7,2.1l-60,31.7C2.4,40.2,0,44.3,0,48.8c0,4.5,2.4,8.6,6.3,10.6
          l4.6,2.5l-4.6,2.5C2.4,66.4,0,70.5,0,75c0,4.5,2.4,8.6,6.3,10.7l4.6,2.4l-4.6,2.5C2.4,92.6,0,96.7,0,101.2c0,4.5,2.4,8.6,6.3,10.7
          l60,31.7c2.7,1.4,5.7,2.1,8.7,2.1c3,0,6-0.7,8.7-2.1l60-31.7c3.9-2.1,6.3-6.1,6.3-10.7c0-4.5-2.4-8.6-6.3-10.6l-4.7-2.5l4.7-2.4
          C147.6,83.6,150,79.5,150,75z M77.4,18.9l56.5,29.8L77.4,78.6c-1.5,0.8-3.3,0.8-4.8,0L16.1,48.8l56.5-29.8
          C74.1,18.2,75.9,18.2,77.4,18.9z M72.6,104.8L16.1,75l9.8-5.2l40.4,21.3c2.7,1.4,5.7,2.1,8.7,2.1c3,0,6-0.7,8.7-2.1l40.4-21.3
          l9.8,5.2l-56.5,29.8C75.9,105.6,74.1,105.6,72.6,104.8z M25.9,96l40.4,21.3c2.7,1.4,5.7,2.2,8.7,2.2c3,0,6-0.8,8.7-2.2L124.1,96
          l9.8,5.2L77.4,131c-1.5,0.8-3.3,0.8-4.8,0l-56.5-29.8L25.9,96z"/>
        </svg>
    </div>

    <!-- <button class="map-type-control equipment" (click)="doClickEquipment()">Abrir Equipamiento</button> -->

  </div>

</div>
