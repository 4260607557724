

<div class="map-filter__header">
  <div class="map-filter__title">Filtros</div>
  <button class="map-filter__toggle" (click)="doToggleFilters()" aria-label="Mostrar/Ocultar filtros">
    <svg-icon class="map-filter__toggle__svg svg-enable" src="assets/img/status-map/toggle-enable.svg"></svg-icon>
    <svg-icon class="map-filter__toggle__svg svg-disable" src="assets/img/status-map/toggle-disable.svg"></svg-icon>
  </button>
</div>

<div class="map-filter__body">

  <div class="map-filter__resume" *ngIf="appliedFilters.length > 0">

    <div class="map-filter__resume__total">
      <div class="map-filter__resume__total__info">Contenedores filtrados: <span>{{ numItemsFiltered }}</span></div>
      <button class="map-filter__resume__clear" (click)="doClearFilters()">Limpiar</button>
    </div>

    <div class="map-filter__resume__items">

      <ng-container *ngFor="let filter of appliedFilters">
        <div class="map-filter__label" (click)="doClickOnFilterApplied(filter)">
          <span>{{ filter.label }}</span>
          <svg width="10" height="10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
            <path fill="currentColor" d="M55.7,15.5L15.4,55.8c0,0-3.4,3.4-6.9-0.2s-0.2-6.9-0.2-6.9L48.5,8.3c0,0,3.5-3.5,7.1,0.1C59.2,12,55.7,15.5,55.7,15.5z"/>
            <path fill="currentColor" d="M48.5,55.7L8.2,15.4c0,0-3.4-3.4,0.2-6.9s6.9-0.2,6.9-0.2l40.3,40.3c0,0,3.5,3.5-0.1,7.1C52,59.2,48.5,55.7,48.5,55.7z"/>
          </svg>
        </div>
      </ng-container>

    </div>

  </div>

  <div class="">

    <lockbin-map-filter-item
      [filter]="filterModel"
      (appliedFilter)="doApplyFilter($event)"
      (removedFilter)="doRemoveFilter($event)"></lockbin-map-filter-item>
    <lockbin-map-filter-item
      [filter]="filterType"
      (appliedFilter)="doApplyFilter($event)"
      (removedFilter)="doRemoveFilter($event)"></lockbin-map-filter-item>
    <lockbin-map-filter-item
      [filter]="filterCategory"
      (appliedFilter)="doApplyFilter($event)"
      (removedFilter)="doRemoveFilter($event)"></lockbin-map-filter-item>
    <lockbin-map-filter-item
      [filter]="filterZone"
      (appliedFilter)="doApplyFilter($event)"
      (removedFilter)="doRemoveFilter($event)"></lockbin-map-filter-item>
    <lockbin-map-filter-item
      [filter]="filterPostalCode"
      (appliedFilter)="doApplyFilter($event)"
      (removedFilter)="doRemoveFilter($event)"></lockbin-map-filter-item>

    <!-- <lockbin-map-filter-item-static></lockbin-map-filter-item-static>
    <lockbin-map-filter-item-static></lockbin-map-filter-item-static>
    <lockbin-map-filter-item-static></lockbin-map-filter-item-static> -->

  </div>

</div>

