
export enum BUS_EVENT_MAP_TYPE {

  MAP_LOADED = 'map.mapLoaded',

  ENABLE_ISSUES = 'map.enableIssues',
  DISABLE_ISSUES = 'map.disableIssues',

  APPLY_ISSUE_FILTER = 'map.applyIssueFilter',

  ENABLE_RECOMMENDATIONS = 'map.enableRecommendations',
  DISABLE_RECOMMENDATIONS = 'map.disableRecommendations',

  APPLY_RECOMMENDATION_FILTER = 'map.applyRecommendationFilter',

  FILTERS_LOADED = 'map.filtersLoaded',

  APPLY_FILTERS = 'map.applyFilters',
  REMOVE_FILTER = 'map.removeFilter',
  CLEAR_FILTERS = 'map.clearFilters',

  OPEN_EQUIPMENT = 'map.openEquipment',


}

