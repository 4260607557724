
<div class="equipment-data" *ngIf="equipment">

  <!-- <div class="status-map__brand">
    <img class="status-map__brand__image" width="250" src="assets/img/logo-fv-des-short.png" alt="Formato Verde">
  </div> -->

  <button class="equipment-data__close" (click)="doClickClose()" aria-label="Cerrar">
    <img class="equipment-data__close__icon" src="./assets/img/status-map/icon-open.png" width="43" height="115" class="img-fluid"/>
  </button>

  <div class="equipment-data__tabs">

    <ul ngbNav #navEquipmentInfo="ngbNav" [(activeId)]="activeTab" class="nav-tabs">
      <li [ngbNavItem]="1">
        <button ngbNavLink>Resumen</button>
        <ng-template ngbNavContent>
          <lockbin-equipment-info-general [equipment]="equipment"></lockbin-equipment-info-general>
        </ng-template>
      </li>
      <li [ngbNavItem]="2">
        <button ngbNavLink>Uso</button>
        <ng-template ngbNavContent>
          <p class="py-3 px-4">
            Ficha de uso, en desarrollo.
          </p>
        </ng-template>
      </li>
      <li [ngbNavItem]="3">
        <button ngbNavLink>Incidencias</button>
        <ng-template ngbNavContent>
          <p class="py-3 px-4">
            Ficha de incidencias, en desarrollo.
          </p>
        </ng-template>
      </li>
      <li [ngbNavItem]="4">
        <button ngbNavLink>Comunicaciones</button>
        <ng-template ngbNavContent>
          <p class="py-3 px-4">
            Ficha de comunicaciones, en desarrollo.
          </p>
        </ng-template>
      </li>
      <li [ngbNavItem]="5">
        <button ngbNavLink>Componentes</button>
        <ng-template ngbNavContent>
          <p class="py-3 px-4">
            Ficha de componentes, en desarrollo.
          </p>
        </ng-template>
      </li>
    </ul>

    <div [ngbNavOutlet]="navEquipmentInfo" class=""></div>

  </div>

</div>
