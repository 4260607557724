// Angular modules
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaderResponse, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

// External modules
import { Observable, throwError, catchError, delay } from 'rxjs';

// Environment
import { environment } from '@env/environment';
import { Router } from '@angular/router';
import { EventBusService } from '@services/event-bus.service';

@Injectable({
  providedIn: 'root'
})
export class DefaultInterceptorService implements HttpInterceptor {

  excludedUrls = [
    'https://maps.googleapis.com/maps/api/',

    'https://api.lockbin.local:8080/api/backend/device/import/',
    'https://api.lockbin.local/api/backend/device/import/',
    'https://api.lockbin.dev/api/backend/device/import/',
    'https://api.lockbin.es/api/backend/device/import/',

    'https://api.lockbin.local:8080/api/backend/card/import/',
    'https://api.lockbin.local/api/backend/card/import/',
    'https://api.lockbin.dev/api/backend/card/import/',
    'https://api.lockbin.es/api/backend/card/import/',

    // TODO: Revisar si la API de autorización tiene que usar el Interceptor
    'https://api.lockbin.local:8080/api/auth/signin',
    'https://api.lockbin.local/api/auth/signin',
    'https://api.lockbin.dev/api/auth/signin',
    'https://api.lockbin.es/api/auth/signin',

  ];

  constructor(private router: Router, private eventBusService: EventBusService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // console.log('🚀 ~ DefaultInterceptorService con REQ:', req);

    // Excluimos el interceptor si la url comienza por alguna de las indicadas.
    if (this.excludedUrls.findIndex((excludedUrl: string) => req.url.startsWith(excludedUrl)) > -1) {
      return next.handle(req);
    }

    req = this.performRequest(req);

    return next.handle(req)
      .pipe(
        delay( environment.delayFake ),
        catchError((error) =>  this.handleDefaultInterceptorError(error) )
      )
  }


  /**
   * Clones original request injectint httpOptions only for http requests (excludes others like i18n)
   * @param req: original request
   * @returns a clon of the original request
   */
  private performRequest(req: HttpRequest<any>) {

    if( req.url.indexOf('http')!==-1 ){
      return req.clone( this.getHttpOptions() )
    }

    return req.clone(req);
  }


  /**
   *
   * @returns A Json object with HttpOptions to be injected in all Http Request
   */
  private getHttpOptions() {
    return {
      // observe: 'response', // --> It's no posible inject this property in Interceptors (https://stackoverflow.com/questions/48543244/how-to-set-the-observe-response-option-globally-in-angular-4-3)
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
    };
  }

  private handleDefaultInterceptorError(err: HttpHeaderResponse) {

    console.log('Error en DefaultInterceptorService: ', err);

    if (err instanceof HttpErrorResponse && err.status === 401) {
        // Specific handling for unauthorized errors
        this.eventBusService.emit({ name: 'lockbin.logout', value: true });
        this.router.navigate(['/autenticacion/acceso']);
    }

    return throwError(() => err);
  }

}
