import { NgModule, inject } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Components
import { PrivateComponent } from './private.component';
import { NotFoundComponent } from '../static/not-found/not-found.component';
import { canAccessGuard } from '../auth/guards/access.guard';

const routes: Routes = [
  {
    path: '',
    component: PrivateComponent,
    children: [
      {
        path: '',
        redirectTo: '/autenticacion/acceso',
        pathMatch: 'full',
      },
      {
        path: 'home',
        loadChildren: () => import('../pages/home/home.module').then(m => m.HomeModule),
      },
      {
        path: 'estadisticas',
        loadChildren: () => import('../stats/stats.module').then(m => m.StatsModule),
      },
      {
        path: 'alertas',
        loadChildren: () => import('../alerts/alerts.module').then(m => m.AlertsModule),
      },
      {
        path: 'tarjetas',
        loadChildren: () => import('../cards/cards.module').then(m => m.CardsModule),
        data: {
          id: 'tarjetas',
          roles: ['ROLE_ROOT', 'ROLE_MANAGER', 'ROLE_CUSTOMER'],
        },
      },
      {
        path: 'administracion',
        data: { headerData: { title: 'Administración' } },
        // canActivate: [canAccessGuard],
        children: [
          {
            path: 'seguridad',
            loadChildren: () => import('../security/security.module').then(m => m.SecurityModule),
            data: { headerData: { title: 'Seguridad' } }
          },
          {
            path: 'configuracion',
            loadChildren: () => import('../configuration/configuration.module').then(m => m.ConfigurationModule),
            data: { headerData: { title: 'Configuración' } }
          },
        ],
      },
      {
        path: 'clientes',
        loadChildren: () => import('../customers/customers.module').then(m => m.CustomersModule),
      },
      {
        path: 'equipos',
        loadChildren: () => import('../equipments/equipments.module').then(m => m.EquipmentsModule),
      },
      {
        path: 'comunicaciones-mqtt',
        loadChildren: () => import('../mqtt/mqtt.module').then(m => m.MqttModule),
      },
    ]
  },
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PrivateRoutingModule { }
