import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StatusMapComponent } from './status-map.component';
import { MapViewComponent } from './pages/map-view/map-view.component';
import { canAccessGuard } from '../auth/guards/access.guard';

// Components

const routes: Routes = [
  { path: '', redirectTo: '/status-map', pathMatch: 'full' },
  {
    path: '',
    component: StatusMapComponent,
    canActivate: [canAccessGuard],
    data: {
      roles: ['ROLE_ROOT', 'ROLE_MANAGER', 'ROLE_CUSTOMER'],
    },
    children: [
      {
        path: 'status-map/equipment/:idEquipment',
        component: MapViewComponent,
      },
      {
        path: 'status-map',
        component: MapViewComponent,
      },

    ],
  },
  // { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StatusMapRoutingModule { }
