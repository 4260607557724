// Angular modules
import { Component, HostBinding, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Subscription, delayWhen, expand, finalize, interval, map, of, takeWhile, timer } from 'rxjs';
import { IMapHeaderData } from '../../models/map-header-data.interface';

@Component({
  selector: 'lockbin-map-sidebar-header',
  templateUrl: './map-sidebar-header.component.html',
  // styleUrls: ['./map-sidebar-header.component.scss'],
})
export class MapSidebarHeaderComponent implements OnInit, OnDestroy, OnChanges {
  @HostBinding('class') componentCssClass = 'map-sidebar-header';

  @Input() headerData!: IMapHeaderData;

  visibleMode = 1;

  counterNumElements = 0;
  counterPercentage = 0;

  private subscriptionElements!: Subscription;
  private subscriptionPercentage!: Subscription;

  constructor(protected logger: NGXLogger) {}

  ngOnInit(): void {
    this.logger.debug('MapSidebarHeaderComponent:ngOnInit');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['headerData']) {
      this.startBehaviour();
    }
  }

  startBehaviour() {
    setTimeout(() => {
      this.visibleMode = 2;
      this.componentCssClass += ' stats-map';

      this.createObservables();
    }, 6000); // 6 segundos de espera
  }

  createObservables() {
    if (this.headerData) {
      // Elementos totales

      if (this.subscriptionElements) {
        this.subscriptionElements.unsubscribe();
      }

      const initialDelay = 1;
      const finalDelay = 60;

      this.subscriptionElements = of(0).pipe(
        expand(count => {
          if (count >= this.headerData.totalEquipments) {
            return of(this.headerData.totalEquipments);
          }

          const progress = count / this.headerData.totalEquipments;
          const intervalDuration = initialDelay + progress * (finalDelay - initialDelay);

          return of(count + 16).pipe(delayWhen(() => timer(intervalDuration)));
        }),
        takeWhile(count => count <= this.headerData.totalEquipments),
        map(count => count as number),
        finalize(() => {
          this.counterNumElements = this.headerData.totalEquipments;
        })
      ).subscribe(count => {
        this.counterNumElements = count;
      });

      // Porcentaje correctos

      if (this.subscriptionPercentage) {
        this.subscriptionPercentage.unsubscribe();
      }

      this.subscriptionPercentage = interval(15)
        .pipe(
          map((value) => value + 4),
          takeWhile((value) => value <= this.headerData.percentageCorrect)
        )
        .subscribe({
          next: (value) => (this.counterPercentage = value),
          // complete: () => console.log('Contador completado'),
        });
    }
  }

  ngOnDestroy(): void {
    if (this.subscriptionElements) {
      this.subscriptionElements.unsubscribe();
    }

    if (this.subscriptionPercentage) {
      this.subscriptionPercentage.unsubscribe();
    }
  }
}
