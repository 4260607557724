
<ng-container *ngIf="filter">

  <div class="map-filter-item__header" [class.show]="showOptions">
    <button class="map-filter-item__toggle" (click)="doToggleOptions()" aria-label="Desplegar">
      <div class="map-filter-item__title">{{ filter.title }}</div>
      <svg width="14" height="14" class="map-filter-item__toggle__svg filter-close" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
        <path fill="currentColor" d="M32,0c3.9,0,7.1,3.2,7.1,7.1v17.8h17.8c3.9,0,7.1,3.2,7.1,7.1c0,3.9-3.2,7.1-7.1,7.1H39.1v17.8c0,3.9-3.2,7.1-7.1,7.1c-3.9,0-7.1-3.2-7.1-7.1V39.1H7.1C3.1,39.1,0,35.9,0,32c0-3.9,3.2-7.1,7.1-7.1h17.8V7.1C24.9,3.1,28.1,0,32,0z"/>
      </svg>
      <svg width="14" height="14" class="map-filter-item__toggle__svg filter-open" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
        <path fill="currentColor" d="M7.1,39.1C3.2,39.1,0,35.9,0,32c0-3.9,3.2-7.1,7.1-7.1h49.8c3.9,0,7.1,3.2,7.1,7.1c0,3.9-3.2,7.1-7.1,7.1H7.1z"/>
      </svg>
    </button>
  </div>

  <div class="map-filter-item__options" [class.show]="showOptions">
    <div class="map-filter-item__options__container">

      <div *ngFor="let filterItem of filter.items"
        class="map-filter-item__label" [class.map-filter-item__label--active]="filterItem.selected"
        (click)="doClickFilter(filterItem)">
        <span>{{ filterItem.label }}</span>
        <svg width="12" height="12" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
          <path fill="currentColor" d="M21.8,55.4c-1.1,0-2.2-0.4-3.1-1.2L1.4,37.8c-1.8-1.7-1.9-4.6-0.2-6.4c1.7-1.8,4.6-1.9,6.4-0.2l14.1,13.3L56.3,10c1.8-1.8,4.6-1.8,6.4,0c1.8,1.8,1.8,4.6,0,6.4L25,54.1C24.1,54.9,23,55.4,21.8,55.4z"/>
        </svg>
      </div>

    </div>
  </div>

</ng-container>
