
// Angular modules
import { Component, EventEmitter, HostBinding, Output } from '@angular/core';
import { OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'lockbin-map-sidebar',
  templateUrl: './map-sidebar.component.html',
  // styleUrls: ['./map-sidebar.component.scss'],
})
export class MapSidebarComponent implements OnInit {

  @HostBinding('class') componentCssClass = 'map-sidebar';

  public options = { autoHide: true, scrollbarMinSize: 100 };

  constructor(protected logger: NGXLogger) {}

  ngOnInit(): void {

    this.logger.debug('MapSidebarComponent:ngOnInit');

  }

}
