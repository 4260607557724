import { Component } from '@angular/core';

@Component({
  selector: 'lockbin-private',
  templateUrl: './private.component.html',
  styles: [
  ]
})
export class PrivateComponent {

}
