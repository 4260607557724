// Angular modules
import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { EventBusService } from '@services/event-bus.service';
import { BUS_EVENT_MAP_TYPE } from '../../enums/bus-event-map-type.enum';
import { IMapIssue } from '../../models/map-issue.interface';

@Component({
  selector: 'lockbin-map-issues',
  templateUrl: './map-issues.component.html',
  // styleUrls: ['./map-issues.component.scss'],
})
export class MapIssuesComponent implements OnInit {
  @HostBinding('class') componentCssClass = 'map-issues';

  @Input() disableBlock: boolean = false;
  @Input() issues!: IMapIssue[];

  @Output() applyIssue: EventEmitter<IMapIssue> = new EventEmitter();
  @Output() removeIssue: EventEmitter<IMapIssue> = new EventEmitter();

  constructor(protected logger: NGXLogger, protected eventBusService: EventBusService) {}

  ngOnInit(): void {
    this.logger.debug('MapIssuesComponent:ngOnInit');
  }

  issuesTotalCounter() {
    let counter = 0;

    if (this.issues) {
      this.issues.forEach((item) => {
        counter += item.quantity;
      });
    }

    return counter;
  }

  hasAnyIssueActive() {
    return this.issues && this.issues.some((item) => item.active);
  }

  doToggleIssue(issue: IMapIssue) {
    if (issue.active) {
      issue.active = false;
      this.removeIssue.emit(issue);
    } else {
      this.issues.forEach((item) => {
        item.active = false;
      });

      issue.active = true;

      // Enviar selección de incidencia
      this.applyIssue.emit(issue);
    }
  }

  doToggleIssues() {
    this.disableBlock = !this.disableBlock;

    if (this.disableBlock) {
      this.componentCssClass = this.componentCssClass + ' disable';
      this.disableAll();

      this.eventBusService.emit({
        name: BUS_EVENT_MAP_TYPE.DISABLE_ISSUES,
        value: true,
      });

      this.removeIssue.emit();
    } else {
      this.componentCssClass = this.componentCssClass.replace(' disable', '');

      this.eventBusService.emit({
        name: BUS_EVENT_MAP_TYPE.ENABLE_ISSUES,
        value: true,
      });
    }
  }

  private disableAll() {
    this.issues.forEach((issue) => {
      issue.active = false;
    });
  }
}
